import React, { useRef, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { TweenLite, TimelineLite, Power3 } from 'gsap';

// Styles
import { Container, Slider, Slide, Actions } from './styles';

// Components
import Menu from '../../components/Menu';
import Scrolling from '../../components/Scrolling';

export default function Selva() {
  // State
  const [activeSlide, setActiveSlide] = useState(0);

  // Animation
  let slides = useRef(null);
  const tl = new TimelineLite();

  function fadeOut(index, duration, delay = 0) {
    TweenLite.to(slides.children[index], duration, {
      position: 'absolute',
      opacity: 0.01,
      visibility: 'hidden',
      ease: Power3.easeOut,
      delay,
    });
  }

  function fadeIn(index, duration, delay = 0) {
    TweenLite.to(slides.children[index], duration, {
      position: 'unset',
      opacity: 1,
      visibility: 'visible',
      ease: Power3.easeOut,
      delay,
    });
  }

  function firstSlideEffect(delay = 0) {
    const firstSlide = slides.children[0];
    const slideImage = firstSlide.children[0].firstChild;
    const slideContent = firstSlide.children[0].lastChild.children;

    tl.to(slides, 1, { opacity: 1, delay }, 'start');

    // prettier-ignore
    tl.staggerFrom([slideImage, ...slideContent], 1, {
      y: 50,
      opacity: 0,
      ease: Power3.easeOut,
      delay: 0.5 + delay
    }, .3, 'start');
  }

  useEffect(() => {
    fadeIn(0, 1);
    firstSlideEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function secondSlideEffect(delay = 0) {
    const secondSlide = slides.children[1];
    const slideImage = secondSlide.children[0].firstChild;
    const slideContent = secondSlide.children[0].lastChild.children;

    // prettier-ignore
    tl.staggerFrom([slideImage, ...slideContent], 1, {
      y: 50,
      opacity: 0,
      ease: Power3.easeOut,
      delay: 0.5 + delay
    }, .3);
  }

  function thirdSlideEffect(delay = 0) {
    const thirdSlide = slides.children[2];
    const slideImage = thirdSlide.children[0].firstChild;
    const slideContent = thirdSlide.children[0].children[1].children;
    const slideLastImage = thirdSlide.children[1];

    // prettier-ignore
    tl.staggerFrom([slideImage, ...slideContent, slideLastImage], 1, {
      y: 50,
      opacity: 0,
      ease: Power3.easeOut,
      delay: 0.5 + delay
    }, .3);
  }

  function fourthSlideEffect(delay = 0) {
    const fourthSlide = slides.children[3];
    const slideImage = fourthSlide.children[0].firstChild;
    const slideContent = fourthSlide.children[0].children[1];
    const slideContentLeft = slideContent.children[0].children;
    const slideContentRight = slideContent.children[2].children;

    tl.to(slideContent.children[1], 0, { opacity: 0.01 });

    // prettier-ignore
    tl.staggerFrom([slideImage, ...slideContentLeft, ...slideContentRight], 1, {
      y: 50,
      opacity: 0,
      ease: Power3.easeOut,
      delay: 0.5 + delay
    }, .2);

    tl.to(slideContent.children[1], 1, { opacity: 1 });
  }

  function nextSlide() {
    if (activeSlide === 0) {
      fadeOut(0, 2);
      fadeIn(1, 2);
      secondSlideEffect();

      setActiveSlide(1);
    } else if (activeSlide === 1) {
      fadeOut(1, 2);
      fadeIn(2, 2);
      thirdSlideEffect();

      setActiveSlide(2);
    } else if (activeSlide === 2) {
      fadeOut(2, 2);
      fadeIn(3, 2);
      fourthSlideEffect();

      setActiveSlide(3);
    } else if (activeSlide === 3) {
      fadeOut(3, 2);
      fadeIn(0, 2);
      firstSlideEffect();

      setActiveSlide(0);
    }
  }

  function prevSlide() {
    if (activeSlide === 1) {
      fadeOut(1, 2);
      fadeIn(0, 2);
      firstSlideEffect();

      setActiveSlide(0);
    } else if (activeSlide === 2) {
      fadeOut(2, 2);
      fadeIn(1, 2);
      secondSlideEffect();

      setActiveSlide(1);
    } else if (activeSlide === 3) {
      fadeOut(3, 2);
      fadeIn(2, 2);
      thirdSlideEffect();

      setActiveSlide(2);
    } else if (activeSlide === 0) {
      fadeOut(0, 2);
      fadeIn(3, 2);
      fourthSlideEffect();

      setActiveSlide(3);
    }
  }

  return (
    <>
      <Helmet>
        <title>Vilanova Amazon - Selva</title>
      </Helmet>
      <Menu />
      <Scrolling>
        <Container>
          <Slider ref={el => (slides = el)}>
            <Slide bg={require('../../assets/images/selva-bg1.jpg')}>
              <div className="slide-01">
                <img
                  src={require('../../assets/images/selva-maior-floresta.png')}
                  alt="A maior floresta"
                />
                <div>
                  <p>
                    Quinto maior país do mundo, o maior da América do Sul e da
                    América Latina, tem população de mais de 200 milhões de
                    habitantes com uma riqueza multicultural e multiétnica,
                    devido ao processo imigratório resultante de vários países
                    do mundo.
                  </p>
                  <p>
                    O local abriga a floresta Amazônica abriga cerca de 1/3 da
                    biodiversidade do mundo. Lá encontra-se um número incrível
                    de plantas, animais exóticos, pássaros e mais de 3 mil
                    espécies diferentes de peixes.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide bg={require('../../assets/images/selva-bg2.jpg')}>
              <div className="slide-01">
                <img
                  src={require('../../assets/images/selva-maior-floresta.png')}
                  alt="A maior floresta"
                />
                <div>
                  <p>
                    Quase do tamanho dos Estados Unidos, a Bacia Amazônica é o
                    maior sistema fluvial de água doce do mundo. O Rio Amazonas
                    começa alto nos Andes peruanos e flui mais de 3 mil milhas
                    para se juntar ao Rio Negro, perto de Manaus.
                  </p>
                  <p>
                    A bacia despeja cerca de 80 milhões de galões de água doce
                    no oceano por segundo, sendo que dentro dela estão os rios
                    Camaiú e Jufari, destinos das nossas aventuras.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide bg={require('../../assets/images/selva-bg3.jpg')}>
              <div className="slide-03">
                <img
                  src={require('../../assets/images/nossa-vida.png')}
                  alt="Essa é a nossa vida"
                />
                <div>
                  <p>
                    A Vilanova Amazon é uma empresa criada em 2010 para atender
                    exclusivamente o Pescador Aventureiro que procura pescar em
                    locais remoto, abrindo mão do luxo para se aventurar nas
                    Águas Amazônicas em busca de peixes-troféu.
                  </p>
                  <p>
                    A nossa proposta é que você e seus amigos se sintam em um
                    ambiente extremamente selvagem com conforto e segurança.
                  </p>
                </div>
              </div>
              <img
                src={require('../../assets/images/tucunare.png')}
                alt="Tucunaré o rei dos rios"
                className="tucunare"
              />
            </Slide>
            <Slide bg={require('../../assets/images/selva-bg4.jpg')}>
              <div className="slide-04">
                <img
                  src={require('../../assets/images/pescador-aqui-lugar.png')}
                  alt="Pescador aventureiro"
                />
                <div>
                  <div>
                    <h3>Empresa</h3>
                    <p>
                      A Vilanova Amazon foi criada em 2010 para atender
                      exclusivamente o Pescador Aventureiro que tem como
                      objetivo pescar nos locais mais remotos das aguas
                      amazônicas em busca de troféus.
                    </p>
                    <p>
                      A nossa proposta é que você e seus amigos se sintam em um
                      ambiente extremamente selvagem com conforto e segurança,
                      garantidos através dos nossos Barcos com suítes
                      climatizadas e das Cabanas de Selva em lugares remotos.
                      Nossas tecnologias oferecem um serviço a mais para nossos
                      clientes, como os rastreadorores via Satélite, rádios de
                      comunicação, barcos de apoio, seguro contra acidentes com
                      remoção aérea de qualquer lugar da Amazônia e Telefones
                      Satelitais disponíveis 24horas e internet Wi-Fi via
                      satélite em todos os Camps alem do serviço in-out com
                      Hidro Avião a partir de Manaus em todos os nossos
                      destinos.
                    </p>
                  </div>
                  <div />
                  <div>
                    <h3>Estrutura</h3>
                    <p style={{ color: '#fff' }}>
                      Ao longo dos anos as estruturas ganharam tamanho e
                      conceitos diferentes bem como acordos firmados com
                      ribeirinhos, autoridades municipais e estaduais para um
                      trabalho preservação do turismo sustentável.
                    </p>
                    <p>
                      Para que você faça uma aventura de verdade, a Vilanova
                      Amazon está de portas abertas para lhe proporcionar uma
                      Expedição de Pesca que fará parte da sua memória para
                      sempre.
                    </p>
                  </div>
                </div>
              </div>
            </Slide>
          </Slider>
        </Container>
      </Scrolling>
      <Actions>
        <button type="button" onClick={prevSlide}>
          <img
            src={require('../../assets/images/arrow-icon.svg')}
            alt="Left Arrow"
          />
        </button>
        <button type="button" onClick={nextSlide}>
          <img
            src={require('../../assets/images/arrow-icon.svg')}
            alt="Right Arrow"
          />
        </button>
      </Actions>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  .body.is-active {
    overflow: hidden;
  }
  @font-face {
    font-family: 'Summer Loving';
    src: url(${require('../../assets/fonts/SummerLoving-Regular.woff2')})
        format('woff2'),
      url(${require('../../assets/fonts/SummerLoving-Regular.woff')})
        format('woff');
    font-weight: normal;
    font-style: normal;
  }

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  z-index: 999;

  > img {
    position: absolute;
    top: -30px;
    left: -4%;
    width: 100%;
    height: 130px;
    z-index: 1;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    max-width: 1140px;
    margin: auto;
    z-index: 9;

    > a {
      margin-right: 70px;
      @media screen and (max-width: 480px) {
        margin-right: 26px;
      }

      img {
        user-select: none;

        @media screen and (max-width: 480px) {
          width: 200px;
        }
      }
    }

    nav {
      width: 100%;

      @media screen and (max-width: 480px) {
        /* display: none; */
      }

      @media screen and (max-width: 480px) {
        .menu {
          width: 100%;
          position: fixed;
          height: 100vh;
          background: #fff;
          top: 0;
          left: 999px;
          z-index: 997;
          transition: all ease-in-out 0.8s;
        }

        .menu.is-active {
          left: 0;
        }
      }
    }

    nav ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;

      @media screen and (max-width: 480px) {
        top: 165px;
        width: 100%;
        position: absolute;
        text-align: center;
        display: inherit;
      }

      li {
        position: relative;
        margin-right: 80px;

        @media screen and (max-width: 480px) {
          margin: auto 40px;
        }

        &:hover:after {
          opacity: 1;
        }

        &:after {
          content: url(${require('../../assets/images/nav-detail.svg')});
          position: absolute;
          left: -30px;
          bottom: 0;
          opacity: 0.01;
          transition: all ease-in-out 0.5s;
          z-index: 8;
        }
      }

      li a {
        position: relative;
        font-family: 'Summer Loving';
        font-weight: normal;
        font-size: 35px;
        color: #9f6300;
        z-index: 9;
        user-select: none;

        @media screen and (max-width: 480px) {
          font-size: 60px;
        }
      }
    }
  }

  .hamburger {
    padding: 15px 15px;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @media screen and (max-width: 480px) {
      display: inline-block;
      z-index: 998;
    }
  }
  .hamburger:hover {
    opacity: 0.7;
    z-index: 998;
  }
  .hamburger.is-active:hover {
    opacity: 0.7;
    z-index: 998;
  }
  .hamburger .hamburger-inner,
  .hamburger .hamburger-inner::before,
  .hamburger .hamburger-inner::after {
    background-color: ${corDoHamburger => corDoHamburger.hamburgerColor};
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }

  /*
   * Squeeze
   */
  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

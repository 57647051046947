import styled from 'styled-components';
import 'react-lightbox-component/build/css/index.css';

export const Bg = styled.div`
  background: url(${require('../../assets/images/bg-camaiu.jpg')}) no-repeat 50%
    50%;
  background-attachment: fixed;
  background-size: 100%;
`;

export const Container = styled.div`
  position: relative;
  visibility: hidden;
  width: 100%;

  .video-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 480px) {
      height: 28vh;
    }

    video {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.3;
    }
  }

  img {
    position: absolute;
    right: -40px;
    bottom: -80px;
    width: 60%;
    user-select: none;

    @media screen and (max-width: 480px) {
      right: 14px;
    }
  }
`;

export const SobreNos = styled.div`
  text-align: right;
  color: #f2f0e4;
  margin-top: -100px;

  .wrap {
    width: 85%;
    max-width: 1140px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;

    @media screen and (max-width: 480px) {
      display: inherit;
    }

    .group {
      position: relative;

      &.left {
        img {
          position: relative;
          left: -15%;

          @media screen and (max-width: 480px) {
            margin-top: 65px;
            width: 288px;
            left: inherit;
          }
        }
      }

      &.right {
        padding-top: 400px;

        @media screen and (max-width: 480px) {
          padding-top: 299px;
        }

        .nome-victor {
          max-width: 150%;
          position: absolute;
          right: 0;
          top: 200px;

          @media screen and (max-width: 480px) {
            max-width: 90%;
            top: 80px;
          }
        }

        strong {
          color: #f4a014;
          font-weight: 400;
        }
      }
    }
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .selva {
      img {
        @media screen and (max-width: 640px) {
          width: 39% !important;
          position: relative;
          left: -5rem;
          top: -3rem;
        }
      }
    }

    ::after {
      content: '';
      width: 100%;
      height: 80rem;
      background: #000;
      opacity: 0;
      position: absolute;
      transition: all ease 200ms;
    }

    a {
      position: absolute;
      top: 50%;
      transform: translate(0, 100%);
      color: #fafafa;
      font-size: 2.5rem;
      z-index: 20;
    }

    @media screen and (max-width: 480px) {
      margin-top: 100px;
    }
    img {
      width: 30%;
      margin-bottom: -70px;

      @media screen and (max-width: 480px) {
        width: 54%;
        margin-bottom: 9px;
      }
    }

    img:nth-child(2) {
      width: 80%;
      position: relative;
    }

    p {
      margin: 0 25%;
      text-align: center;
      font-size: 18px;

      @media screen and (max-width: 480px) {
        margin: 0px 4%;
      }
    }

    strong {
      color: #f4a014;
      font-weight: 400;
    }
  }
`;

export const Grupos = styled.div`
  width: 85%;
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;
  padding: 150px 0;

  @media screen and (max-width: 480px) {
    padding: 60px 0 110px 0;
  }

  h2 {
    background: url(${require('../../assets/images/fundo-titulo.png')})
      no-repeat center center;
    font-size: 28px;
    background-size: contain;
    padding: 100px 0;
    color: #fff;

    @media screen and (max-width: 480px) {
      padding: 45px 0;
    }

    strong {
      color: #f4a014;
      font-weight: 400;
      font-size: 28px;
    }
  }

  .lista-grupos {
    background-size: 100%;
    padding-top: 100px;
    display: flex;
    position: relative;
    margin-top: -50px;

    .BrainhubCarousel__arrows {
      background-color: #ec9717;
    }

    @media screen and (max-width: 480px) {
      grid-gap: 12px;
    }

    &:before {
      content: ' ';
      position: absolute;
      background: url(${require('../../assets/images/fundo-paginacao.png')})
        no-repeat center 0;
      width: 100%;
      height: 139px;
      top: 0;
      background-size: contain;

      @media screen and (max-width: 480px) {
        height: 50px;
      }
    }

    img {
      max-width: 100%;
      transition: all 0.3s ease;
      object-fit: cover;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`;

export const RelatosClientes = styled.div`
  background: url(${require('../../assets/images/rodape-home-topo.png')})
    no-repeat center 0;
  text-align: center;
  background-size: 100%;

  @media screen and (max-width: 480px) {
    background-size: 578px;
  }

  img {
    width: 500px;
    margin: 70px auto 0;

    @media screen and (max-width: 480px) {
      width: 250px;
      margin: 6px auto 0;
    }
  }

  .lista-relatos {
    background: #296c00;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
      display: inherit;
      height: 229px;
    }
  }

  .box-relato {
    padding: 0 60px 0 60px;
    display: flex;
    flex-direction: column;

    img {
      width: 180px;
      margin: inherit;

      @media screen and (max-width: 480px) {
        margin: 6px auto 30px;
      }
    }

    p {
      color: #fff;
      margin-bottom: inherit;
      font-size: 14px;
    }
    a {
      color: #f4a014;
      font-size: 22px;
      transition: all ease-in-out 0.4 s;

      :hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const Footer = styled.div`
  background: url(${require('../../assets/images/rodape-home-footer.png')})
    no-repeat center top #296c00;
  padding: 50px 14% 2% 14%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .holder-2 {
    display: flex;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0 20px;
    flex-direction: column;
    margin-top: -1rem;
  }

  .parceiros {
    margin: 0 0 2rem;

    @media screen and (max-width: 480px) {
      text-align: center;
    }

    h3 {
      color: #fff;
    }

    a {
      transition: all 0.3s ease;
      position: relative;
      top: 0;

      &:hover {
        top: -5px;
      }

      img {
        background: #fff;
        margin: 0 10px 10px 0;
        width: 130px;
        height: 130px;
        object-fit: contain;
        padding: 5px;
      }
    }
  }

  .socials {
    margin-top: 50px;
    margin-left: 5rem;

    @media screen and (max-width: 480px) {
      margin: -1rem 0 0;
    }

    h3 {
      color: #fff;
    }
    img {
      width: 50px;
      margin-right: 20px;
      transition: all ease-in-out 0.4s;

      :hover {
        transform: scale(1.2);
      }
    }
  }
`;

export const Copyright = styled.div`
  @media screen and (max-width: 480px) {
    width: fit-content;
    padding: 2rem 0 0;
  }

  .bottom-text {
    position: relative;
    z-index: 10;
    line-height: 1;

    .test {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 720px) {
        display: initial;
      }
    }

    .signature {
      text-align: center;
      padding: 2rem 0 0;
      font-size: 0.8rem;
      a {
        color: #f4a014;
      }
    }

    h2 {
      color: #fafafa;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      text-align: center;
    }
    .description {
      font-size: 1.2rem;
      margin-top: 1.5rem;
    }
    .grid-footer {
      display: flex;
      gap: 3rem;
      @media screen and (max-width: 720px) {
        display: initial;
      }

      .contato {
        width: 100%;
        margin-bottom: 1rem;
      }
      p {
        padding: 0;
        margin: 0.7rem 0;
      }
      p.number {
        @media screen and (min-width: 1280px) {
          white-space: nowrap;
        }
      }

      .email {
        color: #f4a014;
      }
    }

    p {
      color: #fff;

      span {
        color: #f4a014;
      }
    }
  }
`;

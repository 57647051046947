/* eslint-disable no-return-assign */
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { TweenMax, TimelineLite, Power3 } from 'gsap';
import Lightbox from 'react-lightbox-component';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

// Styles
import {
  Bg,
  Container,
  SobreNos,
  Grupos,
  RelatosClientes,
  Footer,
  Copyright,
} from './styles';

// Subindo na vercel
// Components
import Menu from '../../components/Menu';

export default function Home() {
  // Animations
  const tl = new TimelineLite({ delay: 0.2 });

  let app = useRef(null);
  let exploreImage = useRef(null);

  useEffect(() => {
    TweenMax.to(app, 0, { css: { visibility: 'visible' } });

    // prettier-ignore
    tl.from(app, 3, { opacity: 0, ease: Power3.easeOut })
      .from(exploreImage, 2, { y: 50, opacity: 0, ease: Power3.easeOut }, 0.4);
  }, [tl]);

  return (
    <>
      <Helmet>
        <title>Vilanova Amazon</title>

        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/og_image.png'}
        />
      </Helmet>
      <Menu />

      <Container ref={el => (app = el)}>
        <div className="video-bg">
          <video autoPlay muted loop>
            <source
              src={require('../../assets/videos/360p.mp4')}
              type="video/mp4"
            />
          </video>
        </div>

        <img
          src={require('../../assets/images/home-welcome.png')}
          alt="Welcome Explore the Amazon"
          ref={el => (exploreImage = el)}
        />
      </Container>

      <Bg>
        <SobreNos>
          <div className="wrap">
            <div className="group left">
              <img
                src={require('../../assets/images/victor-vilanova.png')}
                alt="Victor Vilanova"
              />
            </div>
            <div className="group right">
              <img
                src={require('../../assets/images/nome-vilanova.png')}
                alt="Victor Vilanova"
                className="nome-victor"
              />
              <p>
                A Vilanova Amazon é uma empresa criada em 2010 para atender
                exclusivamente o Pescador Aventureiro que procura pescar em
                locais remoto, abrindo mão do luxo para se aventurar nas Águas
                Amazônicas em busca de peixes-troféu.
              </p>
              <p>
                <strong>
                  A nossa proposta é que você e seus amigos se sintam em um
                  ambiente extremamente selvagem com conforto e segurança.
                </strong>
              </p>
            </div>
          </div>
          <div className="center">
            <img
              src={require('../../assets/images/selo-dez-anos.png')}
              alt="10 anos de operações"
            />
            <img
              src={require('../../assets/images/bg10anos.png')}
              alt="Background"
            />
            <a className="selva" href="/selva">
              <img
                src={require('../../assets/images/pescador-aqui-lugar.png')}
                alt="Pescador aventureiro"
                style={{ width: 'auto', marginTop: -95 }}
              />
            </a>

            <p>
              Todas as operações comercializadas pela{' '}
              <strong>Vilanova Amazon</strong> são feitas em{' '}
              <strong>estruturas próprias</strong>, não comercializamos pacotes
              de terceiros.
            </p>
          </div>
        </SobreNos>

        <Grupos>
          <h2>
            Galeria expedições em grupos <strong>Vilanova Amazon</strong>
          </h2>
          <div id="lista-grupos" className="lista-grupos">
            <Carousel arrows>
              <Lightbox
                thumbnailHeight="240px"
                thumbnailWidth="300px"
                images={[
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215387251_ced5d0498e_k.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215596472_3f90a33869_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214856088_82ee0d7e6c_k.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214857353_eb7b04f60e_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214857218_4078ab8962_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338381_8c1f314a60_k.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215339961_04ae4ae221_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215339986_c2134557c8_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214836658_c6bd0d90ef_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                ]}
              />
              <Lightbox
                thumbnailHeight="240px"
                thumbnailWidth="300px"
                images={[
                  {
                    src:
                      'https://live.staticflickr.com/4624/39279931944_ed061a63bf_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215620187_a1ebd40aa2_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214906108_c99cab0ef1_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215349511_93e757439b_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215582517_feda80d1d0_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214857388_84f938fb56_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338171_7892d00313_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338106_548c18c1e9_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338571_653d6a16ef_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                ]}
              />
              <Lightbox
                thumbnailHeight="240px"
                thumbnailWidth="300px"
                images={[
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215571832_a53dc7b788_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214857033_289ca657f4_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338491_2df957a0e1_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338381_e69f552ac6_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338171_7892d00313_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214857078_a460ced688_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215338826_082276e211_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49214856943_64da634303_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                  {
                    src:
                      'https://live.staticflickr.com/65535/49215571962_0cdb973e24_b.jpg',
                    title: ' ',
                    description: ' ',
                  },
                ]}
              />
            </Carousel>
          </div>
        </Grupos>

        <RelatosClientes>
          <img
            src={require('../../assets/images/relatos-clientes-titulo.png')}
            alt="Relatos de clientes"
          />

          <div className="lista-relatos">
            <div className="box-relato">
              <p>Depoimento por:</p>
              <a
                href="http://historiadepescador.com/post.php?id=95"
                target="_blank"
                rel="noopener noreferrer"
              >
                João Medeiros
              </a>
              <img
                src={require('../../assets/images/img-home-5.png')}
                alt="Relatos de clientes"
              />
            </div>
            <div className="box-relato">
              <p>Depoimento por:</p>
              <a
                href="https://blog.pescagerais.com.br/rio-camaiu-amazonas-vilanova-amazon/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Otávio Vieira
              </a>
              <img
                src={require('../../assets/images/img-home-5.png')}
                alt="Relatos de clientes"
              />
            </div>
          </div>
        </RelatosClientes>
      </Bg>

      <Footer>
        <div className="holder-2">
          <div className="parceiros">
            <h3>Parceiros</h3>
            <a
              href="https://www.pescagerais.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/parceiros/pescas-gerais.jpg')}
                alt="Pescas Gerais"
              />
            </a>
            <a
              href="https://historiadepescador.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/parceiros/historia-pescador.png')}
                alt="História de Pescador"
              />
            </a>
            <a
              href="https://www.instagram.com/baquarateam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/parceiros/baquara-team.jpg')}
                alt="Baquara Team"
              />
            </a>
            <a
              href="http://flyshopbrasil.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/parceiros/flyshop-brasil.jpg')}
                alt="Flyshop Brasil"
              />
            </a>
          </div>
          <div className="socials">
            <h3>Nossas redes sociais</h3>
            <a
              href="https://www.instagram.com/vilanovamazon/?hl=pt-br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/instagram.svg')}
                alt="instagram"
              />
            </a>
            <a
              href="https://www.flickr.com/photos/vilanovamazon/35512623402"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/flickr.svg')}
                alt="instagram"
              />
            </a>
            <a
              href="https://pt-br.facebook.com/vilanovamazon/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/images/facebook.svg')}
                alt="instagram"
              />
            </a>
          </div>
        </div>
        <Copyright>
          <div className="bottom-text">
            <h2>Contato</h2>
            <div className="test">
              <div className="grid-footer">
                <p className="description">Vendas / Reservas:</p>
                <div className="contato">
                  <p>
                    Brasil | <span> Eder Nascimento</span>
                  </p>
                  <p className="number">+55 31 9 7300.5051</p>
                </div>
                <div className="contato">
                  <p>
                    Brasil | <span> Luiz Fernando Junior</span>
                  </p>
                  <p className="number">+55 41 99153-0016</p>
                </div>
                <div className="contato">
                  <p>
                    USA | <span> Thiago Zanetti</span>
                  </p>
                  <p className="number">+1 (516) 888-8910</p>
                </div>
                <div className="contato">
                  <p>
                    Diretor | <span> Victor Vilanova</span>
                  </p>
                </div>
                <div>
                  <p>Email</p>
                  <a href="mailto:vilanovamazon@gmail.com" className="email">
                    vilanovamazon@gmail.com
                  </a>
                  <br />
                </div>
              </div>
            </div>
            <p className="signature">
              Feito com ♥ por{' '}
              <a href="https://agenciaade.com.br/">agenciaade.com.br</a>
            </p>
          </div>
        </Copyright>
      </Footer>
    </>
  );
}

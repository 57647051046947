import styled from 'styled-components';

export const Scroll = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 10;
  overflow: hidden;
`;

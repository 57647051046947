import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(${require('../../../assets/images/bg-camaiu.jpg')}) no-repeat
    50% 50%;
  background-size: cover;
  opacity: 0.01;
  z-index: -1;
`;

export const Content = styled.div`
  width: 85%;
  max-width: 1140px;
  margin: auto;
  opacity: 0.01;
  overflow: hidden;

  .group {
    width: 50%;
    @media screen and (max-width: 480px) {
      width: initial;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;

  .left {
    > img {
      margin-top: -50px;
    }

    > p {
      max-width: 450px;
      font-family: 'Averia Gruesa Libre';
      font-weight: normal;
      font-size: 16px;
      text-align: left;
      color: #f2f0e4;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
      margin-top: 30px;
      margin-left: 80px;
      @media screen and (max-width: 480px) {
        margin-left: 0;
      }

      span {
        color: #ffde00;
      }
    }

    ul {
      max-width: 350px;
      margin-top: 70px;
      margin-left: 80px;

      @media screen and (max-width: 480px) {
        margin-left: 0;
      }

      li {
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-gap: 20px;
        align-items: center;
        margin-top: 10px;

        &:first-of-type {
          margin: 0;
        }

        img {
          justify-self: center;
        }

        p {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 16px;
          color: #f2f0e4;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
          margin: 0;

          span {
            color: #ffde00;
          }
        }
      }
    }
  }

  .right {
    img {
      max-width: 100%;
      user-select: none;
      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .camaiu-02 {
      position: relative;
      margin-top: -250px;
      z-index: 4;
    }

    .camaiu-03 {
      position: relative;
      margin-top: -140px;
      z-index: 3;
    }
  }
`;

export const Experience = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;

  figure {
    position: relative;

    @media screen and (max-width: 480px) {
      display: none;
    }

    img {
      width: 100%;
      z-index: 7;

      &:last-of-type {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-48%, -50%) scaleX(-1);
        width: 136%;
        z-index: 8;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10%;
    @media screen and (max-width: 480px) {
      margin-left: 0;
    }

    div {
      margin-top: 30px;
      max-width: 360px;

      p {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        text-align: left;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

        &:first-of-type {
          color: #ffde00;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 235px;
      background: url(${require('../../../assets/images/reserve-bg.png')})
        no-repeat;
      background-size: contain;
      margin-top: 30px;

      img {
        transition: all ease-out 0.2s;
      }

      &:hover {
        img {
          transform: scale(1.08);
        }
      }
    }
  }
`;

export const Amazonia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  @media screen and (max-width: 480px) {
    img {
      width: 100%;
    }
  }

  .temporada {
    display: inline-block;
    align-items: center;
    margin-bottom: 100px;
    position: relative;

    @media screen and (max-width: 480px) {
      img {
        width: 100%;
      }
    }

    img {
      position: absolute;
      width: 40%;

      top: -1.5rem;

      @media screen and (max-width: 480px) {
        width: 100%;
        position: initial;
        top: 0;
        margin-left: 0;
      }
    }

    .viagem {
      text-align: left;
      margin-top: 6rem;

      h3 {
        margin-bottom: 1rem !important;
      }

      h3:last-of-type {
        margin-top: 2rem;
      }

      p {
        margin: 0;
        max-width: inherit;
        align-self: inherit;
        align-self: flex-start;
      }
    }

    .procedimentos {
      position: relative;
      margin-top: 6rem;

      ::after {
        content: '';
        position: absolute;
        top: -1rem;
        height: 1px;
        width: 40%;
        background: #fafafa;
      }

      .alert-text {
        width: 100%;
        margin-top: 4rem;
        text-align: center;
        align-items: initial;

        h4 {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 23px;
          line-height: 22px;
          margin-top: 3rem;
          color: #ffde00;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        }

        p {
          margin: 0;
          font-size: 0.85rem;
          max-width: inherit;
          align-self: inherit;

          span {
            color: #ffde00;
          }
        }
      }
      .grid-procedimentos {
        margin-top: 2rem;
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));

        h4 {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 23px;
          line-height: 22px;
          color: #ffde00;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
          align-self: flex-start;
        }

        h3 {
          margin: 3rem 0;
        }
        p {
          padding: 0 10% 0 0;
          margin: 0;
          max-width: inherit;

          span {
            color: #ffde00;
          }
        }
      }
    }

    .advice {
      margin: -14rem 5rem 0 0;
      display: flex;
      width: 43%;
      flex-direction: column;
      padding: 1rem;
      border-radius: 1rem;
      color: #fafafa;
      font-weight: 800;
      background: #ca6a04;
      @media screen and (max-width: 480px) {
        margin: 3rem 0;
        width: initial;
      }
    }

    .advice p {
      margin: 0;
      max-width: 100%;
      padding: 1rem 0;
    }

    .advice .warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        font-size: 0.8rem;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      @media screen and (max-width: 480px) {
        align-items: flex-start;
      }

      .material-icons {
        margin-right: 0.5rem;
      }

      .left-text {
        margin: 0;
      }

      h3 {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 32px;
        line-height: 22px;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        align-self: flex-start;
        margin-left: 50%;
        @media screen and (max-width: 480px) {
          margin-left: 0;
        }
      }

      h2 {
        font-weight: normal;
        font-size: 26px;
        line-height: 22px;
        color: #ffde00;
        padding-top: 1rem;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        align-self: flex-start;
        margin-left: 50%;
        font-style: italic;

        @media screen and (max-width: 480px) {
          margin-left: 0;
        }
      }

      h2:first-of-type {
        padding-top: 2rem;
      }

      p {
        max-width: 50%;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
        color: #f2f0e4;
        align-self: flex-start;
        margin-left: 50%;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

        @media screen and (max-width: 480px) {
          max-width: initial;
          margin-left: 0;
        }
      }

      .hours .text-final {
        padding-top: 0.5rem;
        font-size: 1.2rem;
      }

      .hours p {
        max-width: 50%;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
        color: #f2f0e4;
        align-self: flex-start;
        margin-left: 50%;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        @media screen and (max-width: 480px) {
          max-width: initial;
          margin-left: 0;
        }

        span {
          margin-right: 1rem;
          color: #7ad21b;
          font-weight: 800;
        }
      }
      .grid-week {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
        padding-left: 50%;
        @media screen and (max-width: 480px) {
          padding-left: 0;
        }

        .item.large {
          width: 26rem;
          @media screen and (max-width: 480px) {
            width: initial;
          }
        }

        h2 {
          padding-top: 1rem;
          margin: 0;
        }
        p {
          margin: 0;
          padding-top: 1rem;
        }
      }
    }
  }

  > img:last-of-type {
    max-width: 75%;
    margin-top: 50px;
  }

  > div {
    width: 100%;

    > img {
      width: 100%;
      object-fit: contain;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      img {
        width: 60%;
        @media screen and (max-width: 480px) {
          width: 100%;
        }
      }

      h3 {
        font-family: 'Averia Libre';
        font-weight: bold;
        font-size: 26px;
        text-align: center;
        color: #ffde00;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        margin-top: 20px;
      }
    }
  }
`;

export const Structure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 480px) {
    img {
      width: 100%;
    }
  }

  > img:first-of-type {
    align-self: flex-start;
    z-index: 9;
  }

  > img:last-of-type {
    margin-top: -220px;
    z-index: 8;
  }
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 85%;
  margin-top: -100px;
  z-index: 9;
  @media screen and (max-width: 480px) {
    grid-template-columns: initial;
    margin-top: 0;
    z-index: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const Commitment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 120px;

    @media screen and (max-width: 480px) {
      grid-template-columns: initial;
      grid-gap: initial;
    }

    &:last-of-type {
      align-items: center;
    }

    a:first-of-type {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 235px;
      background: url(${require('../../../assets/images/reserve-bg.png')})
        no-repeat;
      background-size: contain;

      img {
        transition: all ease-out 0.2s;
      }

      &:hover {
        img {
          transform: scale(1.08);
        }
      }
    }

    a:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 110px;
      background: url(${require('../../../assets/images/voltar-bg.png')})
        no-repeat;
      background-size: contain;
      font-family: 'Averia Libre';
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      color: #f2f0e4;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
      transition: all ease-out 0.2s;

      span {
        color: #f4a014;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  > img {
    width: 110vw;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 100px;
  }
`;

import { createGlobalStyle } from 'styled-components';

// Third-part css libraries
import 'antd/es/style/index.css';
import 'aos/dist/aos.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    outline: 0;
    /* border: 1px solid red; */
  }

  *:focus {
    outline: 0;
  }

  *::selection {
    background: #F29F15 !important;
  }

  body {
    background: #0A0A0A !important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .lightbox-backdrop {
    z-index: 1000;
  }

  body,
  input,
  button {
    font-family: 'Averia Gruesa Libre', cursive !important;
    font-size: 16px !important;
  }
`;

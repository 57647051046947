import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { TweenMax } from 'gsap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { notification } from 'antd';
import 'antd/es/notification/style/css';

// Styles
import { Container, Content, Form, Footer } from './styles';

import ademail from '../../services/ademail';

// Components
import Menu from '../../components/Menu';

export default function Contato() {
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      assunto: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      assunto: Yup.string().required('Campo assunto é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                <p><strong>Nome</strong>: ${values.nome}</p>
                <p><strong>E-mail:</strong> ${values.email}</p>
                <p><strong>Assunto:</strong> ${values.assunto}</p>
                <p><strong>Mensagem:</strong> ${values.mensagem}</p>
              `;

      const mail = {
        to: 'vilanovamazon@gmail.com',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Novo contato via site - VilaNova Amazon',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });
  // Animations
  useEffect(() => {
    TweenMax.to('#container', 1, { css: { opacity: 1 } });
  }, []);

  return (
    <>
      <Helmet>
        <title>Vilanova Amazon - Contato</title>
      </Helmet>
      <Menu />
      <Container id="container">
        <img
          src={require('../../assets/images/home-welcome.png')}
          alt="Explore the amazon"
          id="explore"
        />
        <img
          src={require('../../assets/images/contato-image01.png')}
          alt="Contato"
          id="bg-contato"
        />
        <Content>
          <Form onSubmit={formik.handleSubmit}>
            <h2>Reserve sua aventura</h2>
            <input
              type="text"
              name="nome"
              onChange={formik.handleChange}
              value={formik.values.nome}
              placeholder="Nome"
            />
            {formik.touched.nome && formik.errors.nome ? (
              <span>{formik.errors.nome}</span>
            ) : null}
            <input
              type="text"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="E-mail"
            />
            {formik.touched.email && formik.errors.email ? (
              <span>{formik.errors.email}</span>
            ) : null}
            <input
              type="text"
              name="assunto"
              onChange={formik.handleChange}
              value={formik.values.assunto}
              placeholder="Assunto"
            />
            {formik.touched.assunto && formik.errors.assunto ? (
              <span>{formik.errors.assunto}</span>
            ) : null}
            <textarea
              rows="5"
              name="mensagem"
              onChange={formik.handleChange}
              value={formik.values.mensagem}
              placeholder="Mensagem"
            />
            {formik.touched.mensagem && formik.errors.mensagem ? (
              <span>{formik.errors.mensagem}</span>
            ) : null}
            <button type="submit">Enviar</button>
          </Form>
        </Content>
        <Footer>
          <div className="bottom-text">
            <h2>Contato</h2>
            <div className="test">
              <p className="description">Vendas / Reservas:</p>
              <div className="grid-footer">
                <div className="contato">
                  <p>
                    Brasil | <span> Eder Nascimento</span>
                  </p>
                  <p className="number">+55 31 9 7300.5051</p>
                </div>
                <div className="contato">
                  <p>
                    Brasil | <span> Luiz Fernando Junior</span>
                  </p>
                  <p className="number">41 99153-0016</p>
                </div>
                <div className="contato">
                  <p>
                    USA | <span> Thiago Zanetti</span>
                  </p>
                  <p className="number">+1 (516) 888-8910</p>
                  <br />
                </div>
                <div className="contato">
                  <p>
                    Diretor | <span> Victor Vilanova</span>
                  </p>
                </div>
                <div>
                  <p>Email</p>
                  <a href="mailto:vilanovamazon@gmail.com" className="email">
                    vilanovamazon@gmail.com
                  </a>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Footer>
      </Container>
    </>
  );
}

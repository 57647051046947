import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { TweenMax } from 'gsap';
import AOS from 'aos';

import styled from 'styled-components';

// Styles
// import {
//   Content,
//   Description,
//   Tucunare,
//   Amazonia,
//   Structure,
//   Gallery,
//   Commitment,
//   Main,
// } from './styles';

// Components
import Menu from '../../../components/Menu';

export default function Jufari() {
  // Animations
  useEffect(() => {
    TweenMax.to('#bg', 1, { css: { opacity: 1 } });
    TweenMax.to('#content', 1, { css: { opacity: 1 } });
    AOS.init({ duration: 1000 });
  }, []);

  const Manutencao = styled.section`
    height: 100vh;
    > div {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      h1 {
        font-size: 4rem;
        color: #fff;

        @media (max-width: 680px) {
          font-size: 2rem;
        }
      }
    }
  `;
  return (
    <>
      <Helmet>
        <title>Vilanova Amazon - Adventure Jufari</title>
      </Helmet>
      <Menu />
      <Manutencao>
        <div
          style={{
            backgroundImage:
              'url(' + require('../../../assets/images/bg-jufari.jpg') + ')',
          }}
          className="manutencao"
        >
          <h1>Em manutenção</h1>
        </div>
      </Manutencao>
      {/* <Helmet>
        <title>Vilanova Amazon - Adventure Jufari</title>
      </Helmet>
      <Main
        style={{
          backgroundImage:
            'url(' + require('../../../assets/images/bg-jufari.jpg') + ')',
        }}
      >
        <Menu />
        <Content id="content">
        

          <Description>
            <img
              src={require('../../../assets/images/jufari-inner-logo.png')}
              alt="Adventure Jufari"
              data-aos="fade-down"
            />
            <img
              src={require('../../../assets/images/jufari-image01.png')}
              alt="Explore Jufari"
              data-aos="fade-up"
              data-aos-delay="200"
            />
            <img
              src={require('../../../assets/images/jufari-image02.png')}
              alt="Jufari"
              id="float-right"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <div>
              <div>
                <p data-aos="fade-up" data-aos-delay="300">
                  O Rio Jufaris é um afluente da margem esquerda do Rio Negro
                  que divide os estados do Amazonas e Roraima serpenteando a
                  floresta extremamente preservada, um dos poucos rios da
                  Amazônia Brasileira segundo especialistas que é possível
                  encontrar com abundância os grandes Tucunarés Açus.
                </p>
              </div>
              <div>
                <p data-aos="fade-up" data-aos-delay="400">
                  Um acordo firmado entre a Vilanova Amazon e os proprietários
                  das terras sediadas no alto rio e o governo do estado de
                  Roraima viabilizaram a construção de um Jungle Camp de alto
                  padrão construído todo em madeira de lei certificada usando
                  mão de obra local com design de selva, possui 08 suítes duplas
                  climatizadas em um local extremamente privilegiado para a
                  pesca esportiva.
                </p>
              </div>
              <div>
                <p data-aos="fade-up" data-aos-delay="500">
                  <span>
                    Acessado apenas com voo fretado, são 2:00 horas de vôo a
                    partir de Manaus. Esta é mais uma operação da categoria
                    “Private Waters” pois este local não tem acesso publico para
                    barcos hotéis e afins porque está localizada dentro da APA
                    JUFARI (área de preservação ambiental) do estado de Roraima.
                  </span>
                </p>
              </div>
            </div>
          </Description>
          <Tucunare>
            <div className="group left">
              <figure data-aos="fade-up">
                <img
                  src={require('../../../assets/images/JufariGif.gif')}
                  alt="Map"
                />
                <img
                  src={require('../../../assets/images/jufari-map-frame.png')}
                  alt="Map Bg"
                />
              </figure>
            </div>
            <div className="group right">
              <img
                src={require('../../../assets/images/jufari-image05.png')}
                alt="Descubra a terra do tucunaré"
                data-aos="fade-up"
                data-aos-delay="200"
              />
              <Link to="/contato" data-aos="fade-up">
                <img
                  src={require('../../../assets/images/reserve-text.png')}
                  alt="Reserve sua aventura"
                />
              </Link>
            </div>
          </Tucunare>
          <Amazonia>
            <img
              src={require('../../../assets/images/tucunare-area-restrita.png')}
              alt="Tucunaré em área restrita"
              data-aos="fade-up"
            />
            <img
              src={require('../../../assets/images/jufari-image16.png')}
              alt="Jufari"
              data-aos="fade-up"
            />
            <div className="incluso">
              <ul>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/person-icon.svg')}
                    alt="Person Icon"
                  />
                  <p>
                    <span>Capacidade</span> 08 pessoas
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/sunmoon-icon.svg')}
                    alt="Sun and Moon Icon"
                  />
                  <p>
                    <span>07 dias de viagem sendo 6 dias de pesca com</span>{' '}
                    serviço a partir de Manaus
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/airplane-icon.svg')}
                    alt="Airplane Icon"
                  />
                  <p>
                    <span>Exclusivamente com</span> Hidro Avião
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/woodhouse-icon.svg')}
                    alt="Wooh House Icon"
                  />
                  <p>
                    <span>08 Cabanas de selva duplas com</span> 01 banheiro para
                    cada dupla no Camp
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/bottle-icon.svg')}
                    alt="Bottle Icon"
                  />
                  <p>
                    <span>Cerveja, Água Mineral, Sucos e</span> Refrigerantes à
                    vontade
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/boat-icon.svg')}
                    alt="Boat Icon"
                  />
                  <p>
                    <span>Barcos tipo voadeira com 6m plataformadas e</span> um
                    Guia por duplas, sem limite de gasolina
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/safe-icon.svg')}
                    alt="Safe Icon"
                  />
                  <p>
                    <span>Seguro com remoção aérea acionado via</span> Satélite
                    em caso de risco de vida
                  </p>
                </li>
                <li data-aos="fade-up">
                  <img
                    src={require('../../../assets/images/comunication-icon.svg')}
                    alt="Comunication Icon"
                  />
                  <p>
                    <span>Telefonia e Internet via Satelite</span> Globalstar
                  </p>
                </li>
              </ul>
              <img
                src={require('../../../assets/images/jufari-image03.png')}
                alt="Jufari"
                data-aos="fade-up"
              />
            </div>
            <div className="temporada">
              <img
                src={require('../../../assets/images/jufari-temporada.png')}
                alt="Jufari Temporada"
                data-aos="fade-up"
              />
              <div>
                <h3 data-aos="fade-up">Roteiro</h3>
                <div className="item large">
                  <h2 data-aos="fade-up">Sábado</h2>
                  <p data-aos="fade-up">
                    Chegada a Manaus Pernoite em Hotel Apto Duplo
                  </p>
                </div>
                <h2 data-aos="fade-up">Domingo</h2>
                <div className="hours">
                  <p data-aos="fade-up">
                    <span>05:30</span>
                    Transfer Hotel- Aeroport
                  </p>
                  <p data-aos="fade-up">
                    <span>06:30</span>
                    Decolagem Hidro Avião
                  </p>
                  <p data-aos="fade-up">Pesca após o Almoço</p>
                </div>
                <div className="grid-week">
                  <div className="item">
                    <h2 data-aos="fade-up">Segunda</h2>
                    <p data-aos="fade-up">Pesca</p>
                  </div>
                  <div className="item">
                    <h2 data-aos="fade-up">Terça</h2>
                    <p data-aos="fade-up">Pesca</p>
                  </div>
                  <div className="item">
                    <h2 data-aos="fade-up">Quarta</h2>
                    <p data-aos="fade-up">Pesca</p>
                  </div>
                  <div className="item">
                    <h2 data-aos="fade-up">Quinta</h2>
                    <p data-aos="fade-up">Pesca</p>
                  </div>
                  <div className="item">
                    <h2 data-aos="fade-up">Sexta</h2>
                    <p data-aos="fade-up">Pesca</p>
                  </div>
                  <div className="item">
                  <h2 data-aos="fade-up">Sábado</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
                </div>
                <h2 data-aos="fade-up">Domingo</h2>
                <div className="hours">
                  <p data-aos="fade-up">
                    <span>07:00</span>
                    Café da Manhã
                  </p>
                  <p data-aos="fade-up">
                    <span>09:30</span>
                    vôo Hidro Avião - Manaus
                  </p>
                  <p data-aos="fade-up" className="text-final">
                    Encerramento do programa na chegada ao Aeroporto Eduardo
                    Gomes (MAO)
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" className="advice">
                <div className="warning">
                  <i data-aos="fade-up" className="material-icons">
                    warning
                  </i>
                  <p data-aos="fade-up">
                    LIMITE DE PESO TOTAL É DE 15 KG DE BAGAGEM POR PESSOA
                  </p>
                </div>
                <div className="warning">
                  <i data-aos="fade-up" className="material-icons">
                    warning
                  </i>
                  <p data-aos="fade-up">
                    COMPRAR VÔO COMERCIAL MANAUS- ORIGEM APÓS AS 16:00 HORÁRIO
                    LOCAL.
                  </p>
                </div>
                <div className="warning">
                  <i data-aos="fade-up" className="material-icons">
                    warning
                  </i>
                  <p data-aos="fade-up">
                    NÃO USAR MALAS RÍGIDAS AO ESTILO RODINHA. SOMENTE MOCHILAS E
                    MALAS FLEXÍVEIS SÃO PERMITIDOS
                  </p>
                </div>
              </div>
              <div className="procedimentos">
                <h3 className="left-text" data-aos="fade-up">
                  Procedimentos
                </h3>
                <div data-aos="fade-up" className="grid-procedimentos">
                  <div className="cafe-manha">
                    <h4>Café da Manhã</h4>
                    <p>Servido todos os dias as 05:30</p>
                    <p>
                      Café sem Açúcar, Sucos Regionais, Leite, Pães, Sanduíches,
                      Queijos, Salames, Presunto, Bolos, Frutas, Tortas, Geléias
                      e etc.
                    </p>
                  </div>
                  <div className="almoco">
                    <h4>Almoço</h4>
                    <p>
                      Ao estilo regional feito pelos Guias em um ponto base pré
                      definido.
                    </p>
                    <p>
                      Salada, Arroz, Farofa, Batatas, Banana, Peixes e Carnes
                      Assadas.
                    </p>
                    <p>
                      <span>
                        Não fazemos almoço durante os dias de pesca no Jungle
                        Camp.
                      </span>
                    </p>
                  </div>
                  <div className="jantar">
                    <h4>Jantar</h4>
                    <p>Servido as 20:00</p>
                    <p>Cardápio será variado a cada dia.</p>
                    <p>
                      Sobremesas como Mousse de Cupuaçú, Maracujá e Taperebá,
                      Pudim de Leite, Goiabada, Doces Regionais serão servidos
                      todos os dias.
                    </p>
                  </div>
                </div>
                <div data-aos="fade-up" className="alert-text">
                  <p>
                    Dúvidas sobre cardápios e pedidos especiais, mande um email
                    para <span>vilanovamazon@gmail.com</span>
                  </p>
                  <h4>
                    Todos os Barcos têm horários pré-definidos para o retorno a
                    base
                  </h4>
                  <p>
                    Pedimos a todos que respeitem as normas de segurança. O
                    trabalho de retirar o peixe da água é do Guia de Pesca, após
                    este procedimento o mesmo pode ser fotografado e solto
                    imediatamente. Respeite a natureza acima de tudo, pois
                    estaremos a muitos quilômetros da cidade mais próxima e tudo
                    que for de caráter duvidoso deve ser evitado para sua
                    segurança. Os guias tem autonomia para tomar decisões em
                    caso de problemas de qualquer natureza que eventualmente
                    possam acontecer. Antes de entrar nas águas e na mata, o
                    Guia de Pesca deve ser consultado, pois o mesmo irá avaliar
                    se o ambiente é seguro para tal atividade.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" className="viagem">
                <h3 data-aos="fade-up" className="left-text">
                  Inclusos 07 dias/ 06 dias de Pesca
                </h3>
                <p data-aos="fade-up">
                  • 01 Diária de Hotel em apto duplo na chegada em Manaus *Hidro
                  Avião ida e volta de Manaus para o destino <br /> • 08 Cabanas
                  de Selva Duplas com 01 banheiro para cada dupla no Camp <br />
                  • Seis dias de pesca com revezamento de local obrigatório a
                  cada dia para que seja mantida a baixa pressão dos pontos de
                  pesca. <br />• Cerveja, Água Mineral, Sucos e Refrigerantes à
                  vontade. <br />
                  • Barcos tipo voadeira c/ 6m plataformadas com um Guia por
                  dupla. <br />
                  • Sem Limite de Gasolina.
                  <br /> • Seguro com remoção aérea acionado via Satélite em
                  caso de risco de vida. <br />• Pensão completa com todas as
                  refeições mais serviço de lavanderia.
                </p>
                <h3 data-aos="fade-up" className="left-text">
                  Não inclusos
                </h3>
                <p data-aos="fade-up">
                  • Telefonia via Satélite Globalstar
                  <br />
                  • Internet Wi-Fi via Satelite
                  <br />
                  • Taxa para Acampamentos Avançados <br />• Equipamentos de
                  Pesca <br /> • Licença de Pesca Vôos Origem – Manaus - Origem{' '}
                  <br />
                  • Bebidas Destiladas a venda no Camp <br /> • Despesas de
                  caráter pessoal e translados em Manaus
                  <br />• Gorjetas e outros itens não mencionados explicitamente
                  como inclusos.
                </p>
              </div>
            </div>
            <div className="tucunare">
              <img
                src={require('../../../assets/images/jufari-image06.png')}
                alt="Gigante tucunaré"
                data-aos="fade-up"
              />
              <img
                src={require('../../../assets/images/jufari-image07.png')}
                alt="Jufari"
                data-aos="fade-up"
              />
              <img
                src={require('../../../assets/images/jufari-image08.png')}
                alt="Jufari"
                data-aos="fade-up"
              />
              <img
                src={require('../../../assets/images/jufari-image09.png')}
                alt="Jufari"
                data-aos="fade-up"
              />
            </div>
          </Amazonia>
          <Structure>
            <img
              src={require('../../../assets/images/jufari-image10.png')}
              alt="Estrutura Camaiu"
              data-aos="fade-up"
            />
            <img
              src={require('../../../assets/images/jufari-image11.png')}
              alt="Estrutura"
              data-aos="fade-up"
            />
            <Gallery>
              <img
                src={require('../../../assets/images/jufari-image12.jpg')}
                alt="Expedição"
                data-aos="fade-up"
              />
              <img
                src={require('../../../assets/images/jufari-image13.jpg')}
                alt="Expedição"
                data-aos="fade-up"
                data-aos-delay="100"
              />
              <img
                src={require('../../../assets/images/jufari-image14.jpg')}
                alt="Expedição"
                data-aos="fade-up"
              />
              <img
                src={require('../../../assets/images/jufari-image15.jpg')}
                alt="Expedição"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </Gallery>
          </Structure>
          <Commitment>
            <div>
              <Link to="/contato">
                <img
                  src={require('../../../assets/images/reserve-text.png')}
                  alt="Reserve sua aventura"
                />
              </Link>
              <Link to="/operacoes">
                <p>
                  Voltar para <br /> outra <span>operação</span>
                </p>
              </Link>
            </div>
          </Commitment>
        </Content>
      </Main> */}
    </>
  );
}

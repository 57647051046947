/* eslint-disable import/first */
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './services/history';

// Google Analytics
import { initGA, logPageView } from './utils/googleAnalytics';

// Initialize google analytics page view tracking
initGA();
history.listen(location => logPageView(location));

// Global Styles
import GlobalStyle from './styles/global';

// Components
import ScrollRestoration from './components/ScrollRestoration';

function App() {
  return (
    <Router history={history}>
      <Routes />
      <GlobalStyle />
      <ScrollRestoration />
    </Router>
  );
}

export default hot(App);

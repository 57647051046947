import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Slider = styled.div`
  width: 100%;
  min-height: 100%;
  opacity: 0.01;
`;

export const Slide = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: url(${props => props.bg}) no-repeat;
  background-size: cover;
  opacity: 0.01;
  visibility: hidden;

  .slide-01 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 85%;
    height: auto;
    max-width: 1140px;
    margin: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      margin-right: 80px;
      margin-top: 150px;
      @media screen and (max-width: 480px) {
        width: 100%;
        margin-right: 0;
      }
    }

    > div {
      max-width: 350px;
      margin-top: 30px;
      padding-bottom: 50px;

      p {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        text-align: right;
        color: #f2f0e4;

        &:first-of-type {
          color: #f4a014;
        }
      }
    }
  }

  .slide-03 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    height: auto;
    max-width: 1140px;
    margin: auto;

    img {
      margin-top: 200px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    div {
      margin-top: 35px;
      max-width: 350px;

      p {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

        &:last-of-type {
          margin-top: 25px;
        }
      }
    }
  }

  .tucunare {
    max-width: 50%;
    align-self: flex-end;
    margin-bottom: 60px;
  }

  .slide-04 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    height: auto;
    max-width: 1140px;
    margin: auto;

    img {
      margin-top: 200px;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    > div {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 100px;
      padding: 0 50px;
      margin-top: 50px;
      margin-bottom: 60px;
      @media screen and (max-width: 480px) {
        grid-template-columns: initial;
        grid-gap: 40px;
      }
      div {
        &:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1px;
          height: 80%;
          background: #f4a014;
          opacity: 0.01;
          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        h3 {
          font-family: 'Averia Gruesa Libre';
          font-weight: bold;
          font-size: 20px;
          color: #fff;
          text-transform: uppercase;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        }

        p {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 16px;
          color: #fff;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

          &:last-of-type {
            color: #f4a014;
          }
        }
      }
    }
  }
`;

export const Actions = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  z-index: 99;

  button {
    background: transparent;
    border: none;
    transition: all ease-out 0.2s;
    cursor: pointer;

    &:first-of-type {
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) scale(1.2);
      }
    }

    &:hover {
      transform: scale(1.2);
    }
  }
`;

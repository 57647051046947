import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Pages
import Home from '../pages/Home';
import Selva from '../pages/Selva';
import Operacoes from '../pages/Operacoes';
import Camaiu from '../pages/Operacoes/Camaiu';
import Jufari from '../pages/Operacoes/Jufari';
import Angler from '../pages/Operacoes/Angler';
import Aracu from '../pages/Operacoes/Aracu';
import Contato from '../pages/Contato';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/selva" component={Selva} />
      <Route path="/operacoes" exact component={Operacoes} />
      <Route path="/operacoes/camaiu-camp" component={Camaiu} />
      <Route path="/operacoes/adventure-jufari" component={Jufari} />
      <Route path="/operacoes/angler-boats" component={Angler} />
      <Route path="/operacoes/aracu-camp" component={Aracu} />
      <Route path="/contato" component={Contato} />
    </Switch>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { TweenMax } from 'gsap';
import AOS from 'aos';

// Styles
import {
  Background,
  Content,
  Description,
  Experience,
  Amazonia,
  Structure,
  Gallery,
  Commitment,
} from './styles';

// Components
import Menu from '../../../components/Menu';

export default function Camaiu() {
  // Animations
  useEffect(() => {
    TweenMax.to('#bg', 1, { css: { opacity: 1 } });
    TweenMax.to('#content', 1, { css: { opacity: 1 } });
    AOS.init({ duration: 1000, offset: 20 });
  }, []);

  return (
    <>
      <Helmet>
        <title>Vilanova Amazon - Camaiu Camp</title>
      </Helmet>
      <Menu />
      <Content id="content">
        <Description>
          <div className="group left">
            <img
              src={require('../../../assets/images/camaiu-inner-logo.png')}
              alt="Logo Camaiu"
              data-aos="fade-down"
            />
            <p data-aos="fade-up" data-aos-delay="200">
              Localizado na Cabeceira do lendário Rio Camaiu que está situado
              dentro do Parque Nacional do Acari onde são encontrados os maiores
              Tucunarés Pinimas do mundo, entre as inúmeras cachoeiras
              intransponíveis está fixado o Camaiu Camp. Um conceito exclusivo
              de Jungle Camp com cabanas duplas, banheiros privativos sendo
              acessado única e exclusivamente com vôo charter a partir de
              Manaus. Uma operação da categoria "Private Waters" pois existe ela
              neste local, com isso você está prestes a ter uma experiência
              única de vida selvagem com conforto e segurança levados ao máximo.
              Para os amantes do Fly Fishing, bem vindos ao paraíso das rochas.
            </p>
            <ul>
              <li data-aos="fade-up" data-aos-delay="300">
                <img
                  src={require('../../../assets/images/person-icon.svg')}
                  alt="Person Icon"
                />
                <p>
                  <span>Capacidade</span> 08 pessoas
                </p>
              </li>
              <li data-aos="fade-up" data-aos-delay="300">
                <img
                  src={require('../../../assets/images/sunmoon-icon.svg')}
                  alt="Sun and Moon Icon"
                />
                <p>
                  <span>07 dias de viagem sendo 6 dias de pesca com</span>{' '}
                  serviço a partir de Manaus
                </p>
              </li>
              <li data-aos="fade-up" data-aos-delay="350">
                <img
                  src={require('../../../assets/images/airplane-icon.svg')}
                  alt="Airplane Icon"
                />
                <p>
                  <span>Exclusivamente com</span> Hidro Avião
                </p>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                <img
                  src={require('../../../assets/images/woodhouse-icon.svg')}
                  alt="Wooh House Icon"
                />
                <p>
                  <span>04 Cabanas de selva duplas com</span> 01 banheiro para
                  cada dupla no Camp
                </p>
              </li>
              <li data-aos="fade-up" data-aos-delay="450">
                <img
                  src={require('../../../assets/images/bottle-icon.svg')}
                  alt="Bottle Icon"
                />
                <p>
                  <span>Cerveja, Água Mineral, Sucos e</span> Refrigerantes à
                  vontade
                </p>
              </li>
              <li data-aos="fade-up">
                <img
                  src={require('../../../assets/images/boat-icon.svg')}
                  alt="Boat Icon"
                />
                <p>
                  <span>Barcos tipo voadeira com 6m plataformadas e</span> um
                  Guia por duplas, sem limite de gasolina
                </p>
              </li>
              <li data-aos="fade-up">
                <img
                  src={require('../../../assets/images/safe-icon.svg')}
                  alt="Safe Icon"
                />
                <p>
                  <span>Seguro com remoção aérea acionado via</span> Satélite em
                  caso de risco de vida
                </p>
              </li>
              <li data-aos="fade-up">
                <img
                  src={require('../../../assets/images/comunication-icon.svg')}
                  alt="Comunication Icon"
                />
                <p>
                  <span>Telefonia e Internet via Satelite</span> Globalstar
                </p>
              </li>
            </ul>
          </div>
          <div className="group right">
            <img
              src={require('../../../assets/images/camaiu-image01.png')}
              alt="Camaiu"
              className="camaiu-01"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <img
              src={require('../../../assets/images/camaiu-image02.png')}
              alt="Camaiu"
              className="camaiu-02"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <img
              src={require('../../../assets/images/camaiu-image03.png')}
              alt="Camaiu"
              className="camaiu-03"
              data-aos="fade-up"
              data-aos-delay="500"
            />
          </div>
        </Description>
        <Experience>
          <div className="group left">
            <figure data-aos="fade-up" data-aos-delay="200">
              <img
                src={require('../../../assets/images/camaiu-map.gif')}
                alt="Camaiu"
              />
              <img
                src={require('../../../assets/images/jufari-map-frame.png')}
                alt="Camaiu"
              />
            </figure>
          </div>
          <div className="group right">
            <img
              src={require('../../../assets/images/camaiu-image05.png')}
              alt="Experiência Camaiu"
              data-aos="fade-up"
              data-aos-offset="50"
            />
            <div>
              <p data-aos="fade-up">
                Camaiu Camp está localizado no meio do raio de ação de pesca que
                possui 123 km que podem ser explorados.
              </p>
              <p data-aos="fade-up" data-aos-delay="100">
                Destinado para o pescador que deseja ter um encontro com os
                predadores da amazônia em locais remotos um ambiente
                extremamente selvagem onde o acesso é restrito.
              </p>
            </div>
            <Link to="/contato" data-aos="fade-up">
              <img
                src={require('../../../assets/images/reserve-text.png')}
                alt="Reserve sua aventura"
              />
            </Link>
          </div>
        </Experience>
        <Amazonia>
          <div className="temporada">
            <img
              src={require('../../../assets/images/camaiu-temporada.png')}
              alt="Temporada"
              data-aos="fade-up"
            />
            <div>
              <h3 data-aos="fade-up">Roteiro</h3>
              <div className="item large">
                <h2 data-aos="fade-up">Sábado</h2>
                <p data-aos="fade-up">
                  Chegada a Manaus Pernoite em Hotel Apto Duplo
                </p>
              </div>
              <h2 data-aos="fade-up">Domingo</h2>
              <div className="hours">
                <p data-aos="fade-up">
                  <span>05:30</span>
                  Transfer Hotel- Aeroport
                </p>
                <p data-aos="fade-up">
                  <span>06:30</span>
                  Decolagem Hidro Avião
                </p>
                <p data-aos="fade-up">Pesca após o Almoço</p>
              </div>
              <div className="grid-week">
                <div className="item">
                  <h2 data-aos="fade-up">Segunda</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
                <div className="item">
                  <h2 data-aos="fade-up">Terça</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
                <div className="item">
                  <h2 data-aos="fade-up">Quarta</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
                <div className="item">
                  <h2 data-aos="fade-up">Quinta</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
                <div className="item">
                  <h2 data-aos="fade-up">Sexta</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
                <div className="item">
                  <h2 data-aos="fade-up">Sábado</h2>
                  <p data-aos="fade-up">Pesca</p>
                </div>
              </div>
              <h2 data-aos="fade-up">Domingo</h2>
              <div className="hours">
                <p data-aos="fade-up">
                  <span>07:00</span>
                  Café da Manhã
                </p>
                <p data-aos="fade-up">
                  <span>09:30</span>
                  vôo Hidro Avião - Manaus
                </p>
                <p data-aos="fade-up" className="text-final">
                  Encerramento do programa na chegada ao Aeroporto Eduardo Gomes
                  (MAO)
                </p>
              </div>
            </div>
            <div data-aos="fade-up" className="advice">
              <div className="warning">
                <i data-aos="fade-up" className="material-icons">
                  warning
                </i>
                <p data-aos="fade-up">
                  LIMITE DE PESO TOTAL É DE 15 KG DE BAGAGEM POR PESSOA
                </p>
              </div>
              <div className="warning">
                <i data-aos="fade-up" className="material-icons">
                  warning
                </i>
                <p data-aos="fade-up">
                  COMPRAR VÔO COMERCIAL MANAUS- ORIGEM APÓS AS 16:00 HORÁRIO
                  LOCAL.
                </p>
              </div>
              <div className="warning">
                <i data-aos="fade-up" className="material-icons">
                  warning
                </i>
                <p data-aos="fade-up">
                  NÃO USAR MALAS RÍGIDAS AO ESTILO RODINHA. SOMENTE MOCHILAS E
                  MALAS FLEXÍVEIS SÃO PERMITIDOS
                </p>
              </div>
            </div>
            <div className="procedimentos">
              <h3 className="left-text" data-aos="fade-up">
                Procedimentos
              </h3>
              <div data-aos="fade-up" className="grid-procedimentos">
                <div className="cafe-manha">
                  <h4>Café da Manhã</h4>
                  <p>Servido todos os dias as 05:30</p>
                  <p>
                    Café sem Açúcar, Sucos Regionais, Leite, Pães, Sanduíches,
                    Queijos, Salames, Presunto, Bolos, Frutas, Tortas, Geléias e
                    etc.
                  </p>
                </div>
                <div className="almoco">
                  <h4>Almoço</h4>
                  <p>
                    Ao estilo regional feito pelos Guias em um ponto base pré
                    definido.
                  </p>
                  <p>
                    Salada, Arroz, Farofa, Batatas, Banana, Peixes e Carnes
                    Assadas.
                  </p>
                  <p>
                    <span>
                      Não fazemos almoço durante os dias de pesca no Jungle
                      Camp.
                    </span>
                  </p>
                </div>
                <div className="jantar">
                  <h4>Jantar</h4>
                  <p>Servido as 20:00</p>
                  <p>Cardápio será variado a cada dia.</p>
                  <p>
                    Sobremesas como Mousse de Cupuaçú, Maracujá e Taperebá,
                    Pudim de Leite, Goiabada, Doces Regionais serão servidos
                    todos os dias.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" className="alert-text">
                <p>
                  Dúvidas sobre cardápios e pedidos especiais, mande um email
                  para <span>vilanovamazon@gmail.com</span>
                </p>
                <h4>
                  Todos os Barcos têm horários pré-definidos para o retorno a
                  base
                </h4>
                <p>
                  Pedimos a todos que respeitem as normas de segurança. O
                  trabalho de retirar o peixe da água é do Guia de Pesca, após
                  este procedimento o mesmo pode ser fotografado e solto
                  imediatamente. Respeite a natureza acima de tudo, pois
                  estaremos a muitos quilômetros da cidade mais próxima e tudo
                  que for de caráter duvidoso deve ser evitado para sua
                  segurança. Os guias tem autonomia para tomar decisões em caso
                  de problemas de qualquer natureza que eventualmente possam
                  acontecer. Antes de entrar nas águas e na mata, o Guia de
                  Pesca deve ser consultado, pois o mesmo irá avaliar se o
                  ambiente é seguro para tal atividade.
                </p>
              </div>
            </div>
            <div data-aos="fade-up" className="viagem">
              <h3 data-aos="fade-up" className="left-text">
                Inclusos 07 dias/ 06 dias de Pesca
              </h3>
              <p data-aos="fade-up">
                • 01 Diária de Hotel em apto duplo na chegada em Manaus *Hidro
                Avião ida e volta de Manaus para o destino <br /> • 08 Cabanas
                de Selva Duplas com 01 banheiro para cada dupla no Camp <br />•
                Seis dias de pesca com revezamento de local obrigatório a cada
                dia para que seja mantida a baixa pressão dos pontos de pesca.{' '}
                <br />
                • Cerveja, Água Mineral, Sucos e Refrigerantes à vontade. <br />
                • Barcos tipo voadeira c/ 6m plataformadas com um Guia por
                dupla. <br />
                • Sem Limite de Gasolina.
                <br /> • Seguro com remoção aérea acionado via Satélite em caso
                de risco de vida. <br />• Pensão completa com todas as refeições
                mais serviço de lavanderia.
              </p>
              <h3 data-aos="fade-up" className="left-text">
                Não inclusos
              </h3>
              <p data-aos="fade-up">
                • Telefonia via Satélite Globalstar
                <br />
                • Internet Wi-Fi via Satelite
                <br />
                • Taxa para Acampamentos Avançados <br />• Equipamentos de Pesca{' '}
                <br /> • Licença de Pesca Vôos Origem – Manaus - Origem <br />
                • Bebidas Destiladas a venda no Camp <br /> • Despesas de
                caráter pessoal e translados em Manaus
                <br />• Gorjetas e outros itens não mencionados explicitamente
                como inclusos.
              </p>
            </div>
          </div>
          <img
            src={require('../../../assets/images/desbrave-amazonia.png')}
            alt="Desbrave Amazonia"
            data-aos="fade-up"
          />
          <div className="desbrave">
            <div data-aos="fade-up">
              <img
                src={require('../../../assets/images/camaiu-matrinxa.jpg')}
                alt="Matrinxã"
              />
              <h3>Matrinxã</h3>
            </div>
            <div data-aos="fade-up">
              <img
                src={require('../../../assets/images/camaiu-cachara.jpg')}
                alt=""
              />
              <h3>Cachara</h3>
            </div>
            <div data-aos="fade-up">
              <img
                src={require('../../../assets/images/camaiu-tucunare.jpg')}
                alt=""
              />
              <h3>Tucunaré</h3>
            </div>
          </div>
          <img
            src={require('../../../assets/images/camaiu-image08.png')}
            alt="Camaiu"
            data-aos="fade-up"
          />
        </Amazonia>
        <Structure>
          <img
            src={require('../../../assets/images/camaiu-image09.png')}
            alt="Estrutura Camaiu"
            data-aos="fade-up"
          />
          <img
            src={require('../../../assets/images/camaiu-image10.png')}
            alt="Estrutura"
            data-aos="fade-up"
          />
          <Gallery>
            <img
              src={require('../../../assets/images/camaiu-img1.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
            />
            <img
              src={require('../../../assets/images/camaiu-img2.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <img
              src={require('../../../assets/images/camaiu-img3.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
            />
            <img
              src={require('../../../assets/images/camaiu-img4.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <img
              src={require('../../../assets/images/camaiu-img5.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
            />
            <img
              src={require('../../../assets/images/camaiu-img6.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <img
              src={require('../../../assets/images/camaiu-img7.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
            />
            <img
              src={require('../../../assets/images/camaiu-img8.jpeg')}
              alt="Expedição"
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </Gallery>
        </Structure>
        <Commitment>
          <div>
            <Link to="/contato">
              <img
                src={require('../../../assets/images/reserve-text.png')}
                alt="Reserve sua aventura"
              />
            </Link>
            <Link to="/operacoes">
              <p>
                Voltar para <br /> outra <span>operação</span>
              </p>
            </Link>
          </div>
          <img
            src={require('../../../assets/images/camaiu-end.png')}
            alt="Camaiu"
          />
        </Commitment>
      </Content>
      <Background id="bg" />
    </>
  );
}

import styled from 'styled-components';

export const Holder = styled.section`
  overflow: hidden;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(${require('../../../assets/images/bg-camaiu.jpg')}) no-repeat
    50% 50%;
  background-size: cover;
  z-index: -1;
  opacity: 0.01;
`;

export const Content = styled.div`
  width: 85%;
  max-width: 1140px;
  margin: auto;
  opacity: 0.01;

  .group {
    width: 50%;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 235px;
    background: url(${require('../../../assets/images/reserve-bg.png')})
      no-repeat;
    background-size: contain;
    margin: 30px auto 0 auto;

    img {
      transition: all ease-out 0.2s;
    }

    &:hover {
      img {
        transform: scale(1.08);
      }
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;

  > img:nth-child(1) {
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  img:nth-child(2) {
    top: -10%;
    right: 0;
    width: unset;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  img:nth-child(3) {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  img:nth-child(4) {
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .float-right {
    position: absolute;
    top: 120px;
    right: 2%;
    width: 55%;
    z-index: 2;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    justify-content: center;
    margin-top: 120px;

    @media screen and (max-width: 480px) {
      grid-template-columns: initial;
      grid-gap: initial;
      padding-left: 1rem;
    }

    p {
      max-width: 400px;
      font-family: 'Averia Gruesa Libre';
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #f2f0e4;
      text-align: left;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

      span {
        color: #f41439;
      }
    }
  }
`;

export const Feature = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  align-items: center;
  grid-gap: 20px;

  @media screen and (max-width: 480px) {
    grid-template-columns: initial;
    grid-gap: initial;
    display: initial;
  }

  ul {
    width: 400px;
    margin-top: 70px;
    z-index: 10;
    @media screen and (max-width: 480px) {
      width: initial;
    }

    li {
      display: grid;
      grid-template-columns: 40px 1fr;
      grid-gap: 20px;
      align-items: center;
      margin-top: 10px;

      &:first-of-type {
        margin: 0;
      }

      img {
        justify-self: center;
      }

      p {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        margin: 0;

        span {
          color: #f41439;
        }
      }
    }
  }

  div {
    figure {
      position: relative;
      z-index: 9;
      width: 80%;
      margin-left: 10%;

      img {
        width: 100%;
        z-index: 7;
        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      img:last-of-type {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 135%;
        z-index: 8;
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
    }
  }

  .temporada {
    display: inline-block;
    align-items: center;
    margin-bottom: 100px;
    grid-column: 1/3;
    position: relative;

    img {
      position: absolute;
      width: 40%;
      margin-left: -10%;
      top: -1.5%;

      @media screen and (max-width: 480px) {
        position: initial;
        width: 100%;
      }
    }
    .viagem {
      text-align: left;
      margin-top: 6rem;

      h3 {
        margin-bottom: 1rem !important;
      }

      h3:last-of-type {
        margin-top: 2rem;
      }

      p {
        margin: 0;
        max-width: inherit;
        align-self: inherit;
        align-self: flex-start;
      }
    }

    .procedimentos {
      position: relative;
      margin-top: 6rem;

      ::after {
        content: '';
        position: absolute;
        top: -1rem;
        height: 1px;
        width: 40%;
        background: #fafafa;
      }

      .alert-text {
        width: 100%;
        margin-top: 4rem;
        text-align: center;
        align-items: initial;

        h4 {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 23px;
          line-height: 22px;
          margin-top: 3rem;
          color: #ffde00;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        }

        p {
          margin: 0;
          font-size: 0.85rem;
          max-width: inherit;
          align-self: inherit;

          span {
            color: #ffde00;
          }
        }
      }
      .grid-procedimentos {
        margin-top: 2rem;
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));

        h4 {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 23px;
          line-height: 22px;
          color: #ffde00;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
          align-self: flex-start;
        }

        h3 {
          margin: 3rem 0;
        }
        p {
          padding: 0 10% 0 0;
          margin: 0;
          max-width: inherit;

          span {
            color: #ffde00;
          }
        }
      }
    }

    .advice {
      margin: -14rem 5rem 0 0;
      display: flex;
      width: 43%;
      flex-direction: column;
      padding: 1rem;
      border-radius: 1rem;
      color: #fafafa;
      font-weight: 800;
      background: #ca6a04;

      @media screen and (max-width: 480px) {
        width: 100%;
        margin: 3rem 0;
      }
    }

    .advice p {
      margin: 0;
      max-width: 100%;
      padding: 1rem 0;
    }

    .advice .warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        font-size: 0.8rem;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      @media screen and (max-width: 480px) {
        align-items: flex-start;
      }

      .material-icons {
        margin-right: 0.5rem;
      }

      .left-text {
        margin: 0;
      }

      h3 {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 32px;
        line-height: 22px;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        align-self: flex-start;
        margin-left: 50%;
        @media screen and (max-width: 480px) {
          margin-left: 0;
        }
      }

      h2 {
        font-weight: normal;
        font-size: 26px;
        line-height: 22px;
        color: #ffde00;
        padding-top: 1rem;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        align-self: flex-start;
        margin-left: 50%;
        font-style: italic;

        @media screen and (max-width: 480px) {
          margin-left: 0;
        }
      }

      h2:first-of-type {
        padding-top: 2rem;
      }

      p {
        max-width: 50%;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
        color: #f2f0e4;
        align-self: flex-start;
        margin-left: 50%;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

        @media screen and (max-width: 480px) {
          max-width: initial;
          margin-left: 0;
        }
      }

      .hours .text-final {
        padding-top: 0.5rem;
        font-size: 1.2rem;
      }

      .hours p {
        max-width: 50%;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
        color: #f2f0e4;
        align-self: flex-start;
        margin-left: 50%;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        @media screen and (max-width: 480px) {
          max-width: initial;
          margin-left: 0;
        }

        span {
          margin-right: 1rem;
          color: #7ad21b;
          font-weight: 800;
        }
      }
      .grid-week {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
        padding-left: 50%;
        @media screen and (max-width: 480px) {
          padding-left: 0;
        }

        .item.large {
          width: 26rem;
          @media screen and (max-width: 480px) {
            width: initial;
          }
        }

        h2 {
          padding-top: 1rem;
          margin: 0;
        }
        p {
          margin: 0;
          padding-top: 1rem;
        }
      }
    }
  }
`;

export const Structure = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;

  > img:nth-child(1) {
    z-index: 9;
  }

  > img:nth-child(2) {
    z-index: 9;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  #float-left {
    position: absolute;
    top: 30%;
    left: -50vw;
    transform: translateX(50%);
    z-index: 1;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  > img:last-of-type {
    margin-top: -140px;
    z-index: 8;
  }
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 85%;
  margin-top: 2rem;
  z-index: 9;
  @media screen and (max-width: 480px) {
    grid-template-columns: initial;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 120px;
    align-items: center;
    @media screen and (max-width: 480px) {
      grid-template-columns: initial;
      grid-gap: 0;
      display: initial;
    }

    a:first-of-type {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 235px;
      background: url(${require('../../../assets/images/reserve-bg.png')})
        no-repeat;
      background-size: contain;

      img {
        transition: all ease-out 0.2s;
      }

      &:hover {
        img {
          transform: scale(1.08);
        }
      }
    }

    a:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 110px;
      background: url(${require('../../../assets/images/voltar-bg.png')})
        no-repeat;
      background-size: contain;
      font-family: 'Averia Libre';
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      color: #f2f0e4;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
      transition: all ease-out 0.2s;

      span {
        color: #f4a014;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  > img {
    width: 110vw;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`;

import React, { useEffect } from 'react';
import { TweenLite } from 'gsap';

// Styles
import { Scroll } from './styles';

export default function Scrolling({ children }) {
  // Constants
  const { documentElement: html, body } = document;
  const scroller = {
    target: document.querySelector('#scroll-container'),
    ease: 0.03, // <<- Scroll Speed
    endY: 0,
    y: 0,
    resizeRequest: 1,
    scrollRequest: 0,
  };
  let requestId = null;

  TweenLite.set(scroller.target, { rotation: 0.01, force3D: true });

  // Functions
  function updateScroller() {
    scroller.target = document.querySelector('#scroll-container');

    const resized = 0;

    const scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

    scroller.endY = scrollY;
    scroller.y += (scrollY - scroller.y) * scroller.ease;

    if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
      scroller.y = scrollY;
      scroller.scrollRequest = 0;
    }

    TweenLite.set(scroller.target, { y: -scroller.y });

    requestId =
      scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
  }

  function onScroll() {
    scroller.scrollRequest++;
    if (!requestId) {
      requestId = requestAnimationFrame(updateScroller);
    }
  }

  function onResize() {
    scroller.scrollRequest++;
    if (!requestId) {
      requestId = requestAnimationFrame(updateScroller);
    }
  }

  useEffect(() => {
    updateScroller();
    window.addEventListener('resize', onResize);
    document.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('resize', onResize);
      document.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Scroll id="scroll-container">{children}</Scroll>;
}

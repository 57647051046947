import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Main = styled.section`
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  background: fixed;
  overflow: hidden;
  height: auto;

  @media screen and (min-width: 980px) {
    position: initial;
    background: initial;
    overflow: initial;
    height: initial;
    background-image: none !important;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;

  > img {
    @media screen and (min-width: 980px) {
      width: 100%;
      object-fit: contain;
      user-select: none;
      opacity: 0.01;
    }
    @media screen and (max-width: 980px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  height: auto;
  max-width: 1140px;
  margin-top: 150px;

  @media screen and (max-width: 480px) {
    position: initial;
    left: 0;
    transform: initial;
    max-width: 100%;
    width: 100%;
    padding: 0 2rem;
  }

  #text-image {
    opacity: 0.01;
    @media screen and (max-width: 480px) {
      max-width: 100%;
    }
  }

  div {
    max-width: 350px;
    margin-top: 100px;

    @media screen and (max-width: 480px) {
      max-width: 100%;
    }

    p {
      font-family: 'Averia Gruesa Libre';
      font-weight: normal;
      font-size: 16px;
      text-align: left;
      color: #f2f0e4;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

      &:first-of-type {
        color: #ffde00;
      }
      &:last-of-type {
        width: 32pc;
        @media screen and (max-width: 480px) {
          width: initial;
        }
      }
    }
  }

  .items-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
    position: relative;
    @media screen and (min-width: 1280px) {
      display: none;
    }

    a {
      .bg-items-mobile:not(:nth-child(4)) {
        margin: 0 0 3rem;
      }

      :nth-child(1) {
        .logo-items-mobile {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-58%, 50%);
        }
      }
      :nth-child(2) {
        .logo-items-mobile {
          position: absolute;
          top: 23%;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
      :nth-child(3) {
        .logo-items-mobile {
          position: absolute;
          top: 47%;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
      :nth-child(4) {
        .logo-items-mobile {
          position: absolute;
          top: 65%;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
    }
  }
`;

export const Map = styled.div`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const Circle = styled(Link)`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.radio};
  height: ${props => props.radio};
  border-radius: 100%;
  /* border: 2px solid blue; */
  /* background: #000; */
  background: url(${props => props.bg}) no-repeat;
  background-size: 70%;
  background-position: center center;
  mix-blend-mode: multiply;
  transition: all ease-out 0.2s;
  opacity: 0.01;
  cursor: pointer;
  z-index: 99;

  &:hover {
    transform: scale(1.1);
  }

  /* &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 2px;
    border-radius: 100%;
    background: red;
  } */
`;

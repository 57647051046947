/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Styles
import { Container } from './styles';

export default function Menu(props) {
  const [seMenuAberto, setSeMenuAberto] = useState(false);

  function abreFechaMenu() {
    setSeMenuAberto(!seMenuAberto);
    document.body.classList.toggle('is-active');
  }

  return (
    <Container {...props}>
      <img
        src={require('../../assets/images/menu-bg.png')}
        alt="Menu background"
      />
      <div>
        <Link to="/">
          <img
            src={require('../../assets/images/vilanova-logo.svg')}
            alt="Vilanova"
          />
        </Link>
        <button
          className={`hamburger  hamburger--squeeze ${seMenuAberto &&
            'is-active'}`}
          type="button"
          onClick={abreFechaMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <nav>
          <div className={`menu ${seMenuAberto && 'is-active'}`}>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/selva">A selva</Link>
              </li>
              <li>
                <Link to="/operacoes">Operações</Link>
              </li>
              <li>
                <Link to="/contato">Contato</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Container>
  );
}

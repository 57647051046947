import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TweenLite, TimelineLite } from 'gsap';

// Styles
import { Container, Content, Map, Circle, Main } from './styles';

// Components
import Menu from '../../components/Menu';

export default function Operacoes() {
  // Mapped areas
  const areas = [
    {
      bg: require('../../assets/images/camaiu-logo.png'),
      link: '/operacoes/camaiu-camp',
      top: 365,
      left: 640,
      radius: 170,
    },
    {
      bg: require('../../assets/images/jufari-logo.png'),
      link: '/operacoes/adventure-jufari',
      top: 250,
      left: 760,
      radius: 150,
    },
    {
      bg: require('../../assets/images/angler-logo.png'),
      link: '/operacoes/angler-boats',
      top: 370,
      left: 1190,
      radius: 145,
    },
    {
      bg: require('../../assets/images/aracu-logo.png'),
      link: '/operacoes/aracu-camp',
      top: 270,
      left: 965,
      radius: 150,
    },
  ];

  // State
  const [relativeAreas, setRelativeAreas] = useState([]);

  // Functions
  function relativeHeight(height, radius) {
    const relative = (window.innerWidth * 1172) / 1440;

    return (relative * height) / 1172 - radius / 2;
  }

  function relativeWidth(width, radius) {
    return (window.innerWidth * width) / 1440 - radius / 2;
  }

  function calcPosition() {
    const calc = areas.map(area => {
      return {
        bg: area.bg,
        link: area.link,
        top: relativeHeight(area.top, area.radius),
        left: relativeWidth(area.left, area.radius),
        radius: area.radius,
      };
    });

    setRelativeAreas(calc);
  }

  // On window resize
  useEffect(() => {
    calcPosition();

    window.addEventListener('resize', calcPosition, true);

    return () => {
      window.removeEventListener('resize', calcPosition, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Animations

  useEffect(() => {
    const tl = new TimelineLite();

    TweenLite.to('#bg-exploracao', 1, { opacity: 1 });
    setTimeout(() => {
      tl.fromTo('#text-image', 1, { y: 20, opacity: 0 }, { y: 0, opacity: 1 });

      // prettier-ignore
      tl.staggerFromTo(
      document.querySelector('#map-items').children,
      1.4,
      { opacity: 0 },
      { opacity: 1, stagger: { grid: [1, 6], from: "start", amount: 0.5 } },
    );
      // prettier-ignore
      tl.staggerFromTo(
      document.querySelector('#content').children[1].children,
      1,
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, stagger: 0.3 },
      '-=1.4'
    );
    }, 0.5);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Vilanova Amazon - Expedições</title>
      </Helmet>
      <Menu />
      <Main
        style={{
          backgroundImage:
            'url(' + require('../../assets/images/bg-exploracao.jpg') + ')',
        }}
      >
        <Container>
          <img
            src={require('../../assets/images/bg-exploracao.jpg')}
            alt="Background exploração"
            id="bg-exploracao"
          />
          <Content id="content">
            <img
              id="text-image"
              src={require('../../assets/images/clima-coragem.png')}
              alt="Esse clima é pra quem tem coragem"
            />
            <div>
              <p>
                Próximo a linha do Equador, as temperaturas durante o dia podem
                chegar aos 40ºC. Por isso é recomendável o uso de protetores
                solares de boa qualidade (com alta proteção) e de roupas leves
                para aguentar o calor.
              </p>
              <p>
                À noite sua temperatura cai consideravelmente podendo ser
                inferior aos 19º. O clima típico da floresta tropical pode ser
                acompanhado de chuvas fortes repentinas e úmido, o que pode
                demandar capas de chuva (específicas para o clima tropical –
                como da marca Goretex e similares – porque dissipam o calor de
                dentro para fora e são obrigatórias para seu conforto e
                proteção).
              </p>
            </div>

            <div className="items-mobile">
              <a href="/operacoes/camaiu-camp">
                <img
                  className="bg-items-mobile"
                  src={require('../../assets/images/border-camaiu.png')}
                  alt=""
                />
                <img
                  className="logo-items-mobile"
                  src={require('../../assets/images/camaiu-logo.png')}
                  alt=""
                />
              </a>
              <a href="/operacoes/adventure-jufari">
                <img
                  className="bg-items-mobile"
                  src={require('../../assets/images/border-jufari.png')}
                  alt=""
                />
                <img
                  className="logo-items-mobile"
                  src={require('../../assets/images/jufari-logo.png')}
                  alt=""
                />
              </a>

              <a href="/operacoes/aracu-camp">
                <img
                  className="bg-items-mobile"
                  src={require('../../assets/images/border-aracu.png')}
                  alt=""
                />
                <img
                  className="logo-items-mobile"
                  src={require('../../assets/images/aracu-logo.png')}
                  alt=""
                />
              </a>

              <a href="/operacoes/angler-boats">
                <img
                  className="bg-items-mobile"
                  src={require('../../assets/images/border-angler.png')}
                  alt=""
                />
                <img
                  className="logo-items-mobile"
                  src={require('../../assets/images/angler-logo.png')}
                  alt=""
                />
              </a>
            </div>
          </Content>
          <Map id="map-items">
            {relativeAreas.map(area => (
              <Circle
                to={area.link}
                bg={area.bg}
                top={`${area.top}px`}
                left={`${area.left}px`}
                radio={`${area.radius}px`}
              />
            ))}
          </Map>
        </Container>
      </Main>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 69rem;
  background: url(${require('../../assets/images/bg-camaiu.jpg')}) no-repeat;
  background-size: cover;
  overflow: hidden;
  opacity: 0.01;
  @media screen and (max-width: 720px) {
    height: 169vh;
  }

  #explore {
    position: absolute;
    bottom: 30%;
    left: -10%;
    width: 50%;
    z-index: 9;
    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  #bg-contato {
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 8;

    @media screen and (max-width: 720px) {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  height: 100%;
  max-width: 1140px;
  margin: auto;
  z-index: 10;
`;

export const Form = styled.form`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: 40%;

  span {
    color: #fafafa;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    position: relative;
    top: -10rem;
    align-items: center;
    align-self: initial;
  }

  h2 {
    align-self: center;
    font-family: 'Averia Gruesa Libre';
    font-weight: normal;
    font-size: 30px;
    line-height: 22px;
    text-align: left;
    color: #f2f0e4;
    text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
  }

  input,
  textarea {
    font-family: 'Averia Gruesa Libre';
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #f2f0e4;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0.3);
    margin-top: 20px;
    transition: all ease-out 0.2s;

    @media screen and (max-width: 720px) {
      width: 14rem;
    }

    &::placeholder {
      color: #f2f0e4;
    }

    &:hover,
    &:focus {
      border-color: #f4a014;
    }
  }

  input {
    height: 50px;
    border-radius: 25px;
    text-indent: 25px;
  }

  textarea {
    padding: 10px 0 0 25px;
    border-radius: 25px;
  }

  button {
    align-self: flex-end;
    height: 40px;
    font-family: 'Averia Gruesa Libre';
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 40px;
    text-align: left;
    color: #2e5f4e;
    background: #fff;
    border: 1px solid #f4a014;
    border-radius: 20px;
    margin-top: 20px;
    padding: 0 20px;
    transition: all ease-out 0.2s;
    cursor: pointer;

    @media screen and (max-width: 720px) {
      align-self: initial;
    }

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;

  @media screen and (max-width: 480px) {
    width: fit-content;
    padding: 2rem 0 0;
  }

  .bottom-text {
    margin-top: -215px;
    position: relative;
    z-index: 10;
    line-height: 1;
    width: 120%;
    margin: auto;
    margin-left: -8%;
}

    .test {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 720px) {
        display: initial;
      }
    }

    .signature {
      text-align: center;
      padding: 2rem 0 0;
      font-size: 0.8rem;
      a {
        color: #f4a014;
      }
    }

    h2 {
      color: #fafafa;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      text-align: center;
    }
    .description {
      font-size: 1.2rem;
      margin-top: 1.5rem;
    }
    .grid-footer {
      display: flex;
      gap: 3rem;

      @media screen and (max-width: 720px) {
        display: initial;
      }

      .contato {
        width: 100%;
      }
      p {
        padding: 0;
        margin: 0.7rem 0;
      }

      p.number{
        @media screen and (min-width: 1280px){
          white-space: nowrap;
        }
      }

      .email {
        color: #f4a014;
      }
    }

    p {
      color: #fff;

      span {
        color: #f4a014;
      }
    }
  }
`;

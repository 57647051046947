import styled from 'styled-components';

export const Holder = styled.section`
  overflow: hidden;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(${require('../../../assets/images/bg-angler.jpg')}) no-repeat
    50% 50%;
  background-size: cover;
  z-index: -1;
  opacity: 0.01;
`;

export const Content = styled.div`
  width: 85%;
  max-width: 1140px;
  margin: auto;
  opacity: 0.01;

  .group {
    width: 50%;

    @media screen and (max-width: 480px) {
      width: initial;
    }
  }

  .grid-anglers {
    margin: 8rem 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

    .angler-1 {
      width: 20rem;
      margin: 0 auto;
    }
    .angler-2 {
      width: 20rem;
      margin: 5rem auto 0;
    }
    h2 {
      font-weight: normal;
      font-size: 46px;
      line-height: 22px;
      color: #fafafa;
      padding-top: 1rem;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
    }

    p {
      padding: 0;
      margin: 0;
      font-family: 'Averia Gruesa Libre';
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;
      line-height: 22px;
      color: #f2f0e4;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
    }
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 80px;

  @media screen and (max-width: 480px) {
    display: initial;
  }

  .left {
    z-index: 9;

    > img {
      margin-left: -100px;
    }

    div {
      margin-top: 60px;

      @media screen and (max-width: 480px) {
        img {
          width: 100%;
        }
      }

      p {
        max-width: 450px;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        text-align: left;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        margin-top: 30px;

        span {
          color: #ffde00;
        }

        &:last-of-type {
          text-transform: uppercase;
        }
      }
    }

    ul {
      max-width: 450px;
      margin-top: 70px;

      @media screen and (max-width: 480px) {
        li:nth-child(3) {
          img {
            width: 50%;
          }
        }
      }

      li {
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-gap: 20px;
        align-items: center;
        margin-top: 10px;

        &:first-of-type {
          margin: 0;
        }

        img {
          justify-self: center;
        }

        p {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 16px;
          color: #f2f0e4;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
          margin: 0;

          span {
            color: #ffde00;
          }
        }
      }
    }
  }

  .right {
    z-index: 8;
    margin-top: -60px;

    @media screen and (max-width: 480px) {
      margin-top: 0;
    }

    > img:nth-child(1) {
      width: 150%;
      transform: translateX(-25%) !important;
    }

    > img:nth-child(2) {
      width: 150%;
      transform: translateX(-15%) !important;
      margin-top: -120px;
    }

    figure {
      position: relative;
      z-index: 9;

      img {
        width: 100%;
        z-index: 7;
      }

      img:last-of-type {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 135%;
        z-index: 8;
      }
    }
  }
`;

export const Temporada = styled.div`
  margin-top: 10rem;
  display: inline-block;
  align-items: center;  
  margin-bottom: 100px;

  @media only screen and (max-width: 2120px){
    display: initial;
  }

  .temporada-img{
    width: 32%;
    margin-left: 0;

    @media screen and (max-width: 480px) {
      width: 100%;
      position: initial;
      margin-left: 0;

    }

  }

  .image-angler {
    width: 54%;
    margin-left: 10%;
    @media only screen and (max-width: 2120px){
    display: none;
  } 
  }
  .reserve{
    display: flex;
    margin: 5rem auto;
    height: 10rem;
    width: 65%;
    @media screen and (max-width: 480px) {
      display: block;
      margin: 5rem auto 16rem;
      width: 50%;

    }

    .reserve-img{
      width: 10rem;
    }

    .contact.1{
      width:50%;
    }
    .contact{
    margin-top: 1rem;
    width: 50%;
    padding: 0;
    margin-left: -10%;
    margin-right: 4rem;
    @media screen and (max-width: 480px) {
      width: initial;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
    }
}


      h2{
        margin:0;
        padding: 0;
        max-width: 100%;
      }
      p{
        margin:0;
        padding: 0;
        max-width: 100%;
      }
    }
   

  }

    img {
      position: absolute;
      width: 40%;
      margin-left: -10%;
      @media screen and (max-width: 480px) {
      width: 100%;
      position: initial;
      margin-left: 0;

    }
    }
    .viagem {
      text-align: left;
      margin-top: 6rem;

      h3 {
        margin-bottom: 1rem !important;
      }

      h3:last-of-type {
        margin-top: 2rem;
      }

      p {
        margin: 0;
        max-width: inherit;
        align-self: inherit;
        align-self: flex-start;
      }
    }

    .procedimentos {
      position: relative;
      margin-top: 6rem;

      ::after {
        content: '';
        position: absolute;
        top: 0.6rem;
        height: 1px;
        width: 40%;
        background: #fafafa;
        @media screen and (max-width: 480px) {
      top: -2.6rem;
    }
      }

      .alert-text {
        width: 100%;
        margin-top: 4rem;
        text-align: center;
        align-items: initial;

        h4 {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 23px;
          line-height: 22px;
          margin-top: 3rem;
          color: #feff00;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        }

        p {
          margin: 0;
          font-size: 0.85rem;
          max-width: inherit;
          align-self: inherit;

          span {
            color: #feff00;
          }
        }
      }
      .grid-procedimentos {
        margin-top: 2rem;
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));

        h4 {
          font-family: 'Averia Gruesa Libre';
          font-weight: normal;
          font-size: 23px;
          line-height: 22px;
          color: #feff00;
          text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
          align-self: flex-start;
        }

        h3 {
          margin: 3rem 0;
        }
        p {
          padding: 0 10% 0 0;
          margin: 0;
          max-width: inherit;

          span {
            color: #feff00;
          }
        }
      }
    }

    .advice {
      margin: -14rem 5rem 0 0;
      display: flex;
      width: 43%;
      flex-direction: column;
      padding: 1rem;
      border-radius: 1rem;
      color: #fafafa;
      font-weight: 800;
      background: #ca6a04;
      @media screen and (max-width: 480px) {
      margin: 3rem 0 !important;
      width: 100%;
    }

      @media only screen and (max-width: 2120px){
        margin: -8rem 5rem 0 0;

      }
    }

    .advice p {
      margin: 0;
      max-width: 100%;
      padding: 1rem 0;
    }

    .advice .warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        font-size: 0.8rem;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      @media screen and (max-width: 480px) {
      align-items: flex-start;
    }

      .material-icons {
        margin-right: 0.5rem;
      }

      .left-text {
        margin: 0;
      }

      h3 {
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 32px;
        line-height: 22px;
        color: #f2f0e4;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        align-self: flex-start;
        margin-left: 50%;

        @media screen and (max-width: 480px) {
      margin-left: 0;
    }
      }

      h2 {
        font-weight: normal;
        font-size: 26px;
        line-height: 22px;
        color: #feff00;
        padding-top: 1rem;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        align-self: flex-start;
        margin-left: 50%;
        font-style: italic;
        @media screen and (max-width: 480px) {
      margin-left: 0;
    }
      }

      h2:first-of-type {
        padding-top: 2rem;
      }

      p {
        max-width: 50%;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
        color: #f2f0e4;
        align-self: flex-start;
        margin-left: 50%;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);

        @media screen and (max-width: 480px) {
      margin-left: 0;
      max-width: 100%;
    }
      }

      .hours .text-final {
        padding-top: 0.5rem;
        font-size: 1.2rem;
      }

      .hours p {
        max-width: 50%;
        font-family: 'Averia Gruesa Libre';
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
        color: #f2f0e4;
        align-self: flex-start;
        margin-left: 50%;
        text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
        @media screen and (max-width: 480px) {
      margin-left: 0;
      max-width: 100%;
    }

        span {
          margin-right: 1rem;
          color: #84ff00;
          font-weight: 800;
        }
      }
      .grid-week {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
        padding-left: 50%;
        @media screen and (max-width: 480px) {
      padding-left: 0;
    }

        .item.large{
          width: 26rem;
          @media screen and (max-width: 480px) {
      width: initial;
    }
        }

        h2 {
          padding-top: 1rem;
          margin: 0;
        }
        p {
          margin: 0;
          padding-top: 1rem;
        }
      }
    }
    }


  .tucunare {
    position: relative;
    width: 100%;
    height: 1240px;
    margin-top: 50px;

    img:nth-child(1) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) !important;
      width: 75%;
      z-index: 6;
      @media screen and (max-width: 480px) {
      width: 100%;
    }
    }

    img:nth-child(2) {
      position: absolute;
      top: 330px;
      left: 0;
      transform: translateX(-5%) !important;
      width: 60%;
      z-index: 7;
      @media screen and (max-width: 480px) {
      width: 100%;
    }
    }

    img:nth-child(3) {
      position: absolute;
      top: 230px;
      right: 0;
      transform: translateX(5%) !important;
      width: 60%;
      z-index: 8;
      @media screen and (max-width: 480px) {
      width: 100%;
    }
    }

    img:nth-child(4) {
      position: absolute;
      top: 660px;
      left: 0;
      transform: translateX(-15%) !important;
      width: 70%;
      z-index: 6;
      @media screen and (max-width: 480px) {
      width: 100%;
    }
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 235px;
    background: url(${require('../../../assets/images/reserve-bg.png')})
      no-repeat;
    background-size: contain;
    margin: 30px auto 0 auto;

    img {
      transition: all ease-out 0.2s;
    }

    &:hover {
      img {
        transform: scale(1.08);
      }
    }
  }
`;

export const Structure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;

  @media only screen and (max-width: 2120px) {
    margin-top: 6rem;
  }

  > img:first-of-type {
    z-index: 9;
    @media only screen and (max-width: 2120px) {
      z-index: 99;
      width: 100%;
    }
  }

  > img:last-of-type {
    width: 100%;
    margin-top: -140px;
    z-index: 8;
  }
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 85%;
  margin-top: -130px;
  z-index: 9;
  @media screen and (max-width: 480px) {
    grid-template-columns: initial;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;

  .reserve {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 4rem auto;
    align-items: center;

    @media only screen and (max-width: 2120px) {
      display: initial;
    }

    .contact:first-of-type {
      margin: -2rem 2rem 0 4rem;
      @media only screen and (max-width: 2120px) {
        margin: 0 2rem 0 4rem;
      }
    }
    .contact:last-of-type {
      margin: -2rem 2rem 0 1rem;
      @media only screen and (max-width: 2120px) {
        margin: 1rem 2rem 0 4rem;
      }
    }

    h2 {
      font-weight: normal;
      font-size: 26px;
      line-height: 22px;
      color: #feff00;
      padding-top: 1rem;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
      align-self: flex-start;
      margin-left: 0;
      font-style: italic;
    }
    p {
      font-family: 'Averia Gruesa Libre';
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;
      line-height: 22px;
      color: #f2f0e4;
      align-self: flex-start;
      margin-left: 0;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    a:first-of-type {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 235px;
      background: url(${require('../../../assets/images/reserve-bg.png')})
        no-repeat;
      background-size: contain;

      img {
        transition: all ease-out 0.2s;
      }

      &:hover {
        img {
          transform: scale(1.08);
        }
      }
    }

    a:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 110px;
      background: url(${require('../../../assets/images/voltar-bg.png')})
        no-repeat;
      background-size: contain;
      font-family: 'Averia Libre';
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      color: #f2f0e4;
      text-shadow: 10px 10px 21px rgba(0, 0, 0, 0.57);
      transition: all ease-out 0.2s;

      span {
        color: #f4a014;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  > img {
    width: 110vw;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`;
